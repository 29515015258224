import { FC } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import { eventDetailSendAssistantClickGAData } from 'src/app/common/ga/types/ga-event';
import { regionLocale } from 'src/app/i18n';
import { SendLinkDialogState } from 'src/app/modules/event-v2/types';
import { useSendLinkDialog } from './send-link-dialog.hook';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useParams } from 'react-router-dom';

type SendLinkDialogProps = {
  dialogState: SendLinkDialogState;
  onClose: () => void;
  resetDialogState: () => void;
};

export const SendLinkDialog: FC<SendLinkDialogProps> = ({ dialogState, onClose, resetDialogState }) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const { open, selectedRows } = dialogState;
  const { formState, formDispatch, onSubmit } = useSendLinkDialog({
    eventId: id,
    dialogState,
    resetDialogState,
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
      <DialogTitle>{t('event.form.send_link_remark', { num: selectedRows.length.toString() })}</DialogTitle>
      <DialogContent>
        <FormControl required fullWidth sx={{ my: 1 }} color="secondary">
          <InputLabel>{t('event.form.language_preference')}</InputLabel>
          <Select
            value={formState.lang || ''}
            label={t('event.form.language_preference')}
            onChange={(e) => {
              formDispatch({
                type: 'MODIFY_FIELD',
                payload: {
                  field: 'lang',
                  value: e.target.value,
                },
              });
            }}
          >
            {regionLocale.map((option) => (
              <MenuItem key={option} value={option}>
                {t(`navBar.lang.${option}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          disabled
          required
          fullWidth
          variant="outlined"
          color="secondary"
          sx={{ my: 2 }}
          label={t('event.common.title')}
          value={formState.title[formState.lang]}
        />
        <TextField
          disabled
          required
          fullWidth
          multiline
          color="secondary"
          variant="outlined"
          label={t('event.common.body')}
          value={formState.body[formState.lang]}
        />
      </DialogContent>
      <DialogActions style={{ paddingBottom: 20 }}>
        <Button variant="outlined" color="info" onClick={onClose}>
          {t('app.button.cancel')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            takeUIClickEvent(eventDetailSendAssistantClickGAData);
            onSubmit();
          }}
        >
          {t('app.button.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

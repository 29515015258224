import { useState, useReducer, useMemo, useEffect } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { regionLocale } from 'src/app/i18n';
import { useErrorHandler, ErrorFieldType } from 'src/app/common/utils';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { takeUIScreenViewEvent } from 'src/app/common/ga/ga';
import { eventDetailPublishGAData } from 'src/app/common/ga/types/ga-event';
import {
  StepStatusEnum,
  ComponentProps,
  StepIndicator,
  useUpdateChecker,
} from 'src/app/common/components/pru-stepped-form-agent';
import {
  EventPublishStatusEnum,
  PublishAgentEnum,
  RegistrationMethodEnum,
  EventImageTypeEnum,
  EventFormCommonProps,
  EventItem,
} from 'src/app/modules/event-v2/types';
import { CreateUpdateEventBody, modifyEventItem } from 'src/app/modules/event-v2/network';
import { eventListPath } from '../../../../event-list-routes';
import { PublishFormState, publishFormReducer } from '../../../reducers';
import { validateRegAllowWalkIn } from '../../../common';
import { initEventAgentConfig } from './constants';

type HookProps = ComponentProps<EventFormCommonProps>;

const detailToStateConvertor = (eventItem?: EventItem): PublishFormState => {
  return eventItem
    ? {
        pubAgentConfig: eventItem.pubAgentConfig || initEventAgentConfig,
      }
    : {
        pubAgentConfig: initEventAgentConfig,
      };
};

const checkInvalidSelectEventImage = (eventItem: EventItem) => {
  for (const locale of regionLocale) {
    if (
      eventItem.eventImageType?.[locale] === EventImageTypeEnum.TEMPLATE &&
      eventItem.selectedEventImage?.[locale]?.isActive === false
    ) {
      return true;
    }
  }
  return false;
};

export const usePublishForm = ({ formCommonProps, componentUpdateStepStatus, setDiscardChanges }: HookProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { eventItem, isReset, setChangesUnsaved, setIsReset } = formCommonProps;
  const initialState = useMemo(() => detailToStateConvertor(eventItem), [eventItem]);
  const [formState, formDispatch] = useReducer(publishFormReducer, initialState);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, resetErrorState } = useErrorHandler(formState, [
    {
      name: 'pubAgent-eligibleAgents',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => formState.pubAgentConfig.eligibleAgents.length === 0,
    },
  ]);

  const resetFormState = () => {
    formDispatch({
      type: 'SET_FORM_STATE',
      payload: {
        value: initialState,
      },
    });
    resetErrorState();
    componentUpdateStepStatus({
      newStatus: eventItem?.pubAgentConfig ? StepStatusEnum.FINISHED : StepStatusEnum.PROGRESS,
      saveChanges: true,
      forceUpdateStatus: true,
    });
    setIsReset(true);
  };

  useEffect(() => {
    takeUIScreenViewEvent(eventDetailPublishGAData);
    setDiscardChanges(resetFormState);
    return () => {
      setDiscardChanges(undefined);
    };
  }, []);

  useUpdateChecker({ formState, isReset, setChangesUnsaved, setIsReset });

  const onSubmit = async (publishStatus?: EventPublishStatusEnum) => {
    let hasError = false;
    if (!publishStatus) {
      hasError = onSubmitErrorValidator().hasError;
    }
    if (!hasError) {
      const body: CreateUpdateEventBody = {
        pubAgent: PublishAgentEnum.TARGET,
        pubAgentConfig: formState.pubAgentConfig,
        publishStatus,
      };
      try {
        if (eventItem) {
          await modifyEventItem(eventItem._id, body, dispatch);
          if (!publishStatus) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: 'Event saved successfully',
                },
              ]),
            );
          } else if (publishStatus === EventPublishStatusEnum.PUBLISHED) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: 'Event published successfully',
                },
              ]),
            );
          }
          history.push(`${eventListPath}`);
        }
      } catch (err) {
        componentUpdateStepStatus({ newStatus: StepStatusEnum.WARNING, forceNavigation: true, saveChanges: true });
      }
    } else {
      componentUpdateStepStatus({ newStatus: StepStatusEnum.WARNING });
    }
  };

  const publishValidation = () => {
    const errorStepList: StepIndicator[] = [];
    const errorStepTitles: string[] = [];
    if (eventItem) {
      if (!eventItem.eventImageType || checkInvalidSelectEventImage(eventItem)) {
        // Details Form
        errorStepList.push({ stepIndex: 1 });
        errorStepTitles.push(`"Details"`);
      }
      if (!eventItem.regStartDate || !validateRegAllowWalkIn(eventItem)) {
        // Registration & Attendance Form
        errorStepList.push({ stepIndex: 2 });
        errorStepTitles.push(`"Registration & Attendance"`);
      }
      if (eventItem.regMethod === RegistrationMethodEnum.TEMPLATE && !eventItem.regFormBody) {
        // Registration Form
        errorStepList.push({ stepIndex: 3 });
        errorStepTitles.push(`"Registration Form"`);
      }
    }
    return { errorStepList, errorStepTitles };
  };

  const onPublish = () => {
    const { errorStepList, errorStepTitles } = publishValidation();
    if (errorStepList.length === 0) {
      const { hasError } = onSubmitErrorValidator();
      if (!hasError) {
        if (eventItem) {
          const { startTime, regEndDate } = eventItem;
          const now = new Date();
          if (regEndDate && moment(now).isSameOrAfter(regEndDate)) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  title: 'Error',
                  content: 'Registration end time cannot be past time',
                },
              ]),
            );
          } else if (moment(now).isSameOrAfter(startTime)) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  title: 'Error',
                  content: 'Event date cannot be past date',
                },
              ]),
            );
          } else if (moment(now).isSame(startTime, 'day')) {
            setShowConfirmDialog(true);
          } else {
            onSubmit(EventPublishStatusEnum.PUBLISHED);
          }
        }
      } else {
        componentUpdateStepStatus({ newStatus: StepStatusEnum.WARNING });
      }
    } else {
      const errorToast = `These section(s) are not completed: ${errorStepTitles.join(', ')}`;
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: errorToast,
          },
        ]),
      );
      componentUpdateStepStatus({
        newStatus: StepStatusEnum.WARNING,
        forceNavigation: true,
        saveChanges: true,
        stepIndicatorList: errorStepList,
      });
    }
  };

  return {
    formState,
    showConfirmDialog,
    errorState,
    formDispatch,
    setShowConfirmDialog,
    onDismissErrorHandler,
    onSubmit,
    onPublish,
  };
};

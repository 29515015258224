import { FC } from 'react';
import moment from 'moment';
import { map } from 'lodash';
import { useIntl } from 'react-intl';
import {
  Autocomplete,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  Select,
  MenuItem,
  Paper,
  RadioGroup,
  Radio,
  TextField,
  Tooltip,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import { regionLocale, useLang, initI18nGenericData } from 'src/app/i18n';
import {
  MANDATORY_FIELD_ERROR_TEXT,
  TIME_ERROR_TEXT,
  NUMBER_NA_ONLY_ERROR_TEXT,
  trueFalseOptions,
} from 'src/app/common/constants';
import { FormMode } from 'src/app/common/types';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import { eventDetailToEventDetailsSaveClickGAData } from 'src/app/common/ga/types/ga-event';
import { ComponentProps } from 'src/app/common/components/pru-stepped-form-agent';
import { PruDatePicker, PruTimePicker } from 'src/app/common/components/PruDatePicker';
import { AgentApplicantInfo } from 'src/app/common/components/agent-applicant-info';
import { EventTypeEnum, OnlineMeetingLinkDisplayEnum, EventFormCommonProps } from 'src/app/modules/event-v2/types';
import { PublishStatusTag } from '../../../../list/components';
import { BasicsFormState } from '../../../reducers';
import { useCommonFormStyles, MultiLangSection, TopButton } from '../../../common';
import { useBasicsStyles } from './basics-form.style';
import { useBasicsForm } from './basics-form.hook';
import { SessionSetting } from './components/session-setting';

type EventBasicsFormProps = ComponentProps<EventFormCommonProps>;

export const EventBasicsForm: FC<EventBasicsFormProps> = ({ formCommonProps, ...rest }) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const locale = useLang();
  const { classes } = useBasicsStyles();
  const { classes: commonFormClasses } = useCommonFormStyles();
  const { formMode, isPublished, disableEdit, eventCategoryItem, eventTagList, eventItem } = formCommonProps;
  const { formState, errorState, isPublicEvent, isVirtual, formDispatch, onDismissErrorHandler, onSubmit } =
    useBasicsForm({
      formCommonProps,
      ...rest,
    });
  const viewMode = formMode === FormMode.VIEW;

  return (
    <>
      <TopButton
        formMode={formMode}
        eventItem={eventItem}
        disableSave={formState.disableSave}
        onSave={() => {
          takeUIClickEvent(eventDetailToEventDetailsSaveClickGAData);
          onSubmit();
        }}
      />
      <div className={commonFormClasses.paperContainer}>
        <Paper className={classes.info}>
          <div className={classes.publishStatusContainer}>
            <span className={classes.publishStatusLabel}>{Translation('component.formLabel.publish-status')}</span>
            <PublishStatusTag publishStatus={eventItem?.publishStatus} />
          </div>
          <div className={classes.categoryInfoContainer}>
            <div className={classes.categoryInfo}>
              <div className={classes.categoryLabel}>{Translation('event.common.category')}</div>
              <div className={classes.categoryText}>{eventCategoryItem?.name[locale] || '-'}</div>
            </div>
            <div className={classes.categoryInfo}>
              <div className={classes.categoryLabel}>{Translation('event.common.accessibility')}</div>
              <div className={classes.categoryText}>
                {eventCategoryItem
                  ? Translation(`event.common.accessibility.${eventCategoryItem.accessibility.toLowerCase()}`)
                  : '-'}
              </div>
            </div>
            <div className={classes.categoryInfo}>
              <div className={classes.categoryLabel}>{Translation('event.common.audience')}</div>
              <div className={classes.categoryText}>
                {eventCategoryItem
                  ? Translation(`event.common.audience.${eventCategoryItem.audience.toLowerCase()}`)
                  : '-'}
              </div>
            </div>
          </div>
        </Paper>
        {isPublicEvent && (
          <Paper className={commonFormClasses.paper}>
            <div className={commonFormClasses.titleContainer}>{Translation('event.form.purpose')}</div>
            <div className={commonFormClasses.smallGapContentContainer}>
              <FormControl
                fullWidth
                disabled={viewMode || isPublished || disableEdit}
                error={errorState.mandatory.shareToPublic}
              >
                <FormLabel className={commonFormClasses.formLabel}>
                  {Translation('event.form.share_to_public')}
                  <span className={commonFormClasses.mandatory}>*</span>
                </FormLabel>
                <RadioGroup
                  className={commonFormClasses.radioGroup}
                  value={typeof formState.shareToPublic === 'boolean' ? formState.shareToPublic.toString() : ''}
                  onChange={(e) => {
                    onDismissErrorHandler('shareToPublic', true);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: {
                        field: 'shareToPublic',
                        value: e.target.value === 'true' ? true : false,
                      },
                    });
                  }}
                >
                  {trueFalseOptions.map((option) => (
                    <FormControlLabel
                      key={option}
                      className={commonFormClasses.formControlLabel}
                      control={<Radio />}
                      label={Translation(`event.form.share_to_public.${option}`)}
                      value={option}
                    />
                  ))}
                </RadioGroup>
                {errorState.mandatory.shareToPublic && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
              </FormControl>
              <FormControl
                fullWidth
                disabled={viewMode || isPublished || disableEdit}
                error={errorState.mandatory.agentRequired}
              >
                <FormLabel className={commonFormClasses.formLabel}>
                  {Translation('event.form.agent_required')}
                  <span className={commonFormClasses.mandatory}>*</span>
                </FormLabel>
                <RadioGroup
                  className={commonFormClasses.radioGroup}
                  value={typeof formState.agentRequired === 'boolean' ? formState.agentRequired.toString() : ''}
                  onChange={(e) => {
                    onDismissErrorHandler('agentRequired', true);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: {
                        field: 'agentRequired',
                        value: e.target.value === 'true' ? true : false,
                      },
                    });
                  }}
                >
                  {trueFalseOptions.map((option) => (
                    <FormControlLabel
                      key={option}
                      className={commonFormClasses.formControlLabel}
                      control={<Radio />}
                      label={Translation(`event.form.agent_required.${option}`)}
                      value={option}
                    />
                  ))}
                </RadioGroup>
                {errorState.mandatory.agentRequired && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
              </FormControl>
            </div>
          </Paper>
        )}
        <AgentApplicantInfo />
        <Paper className={commonFormClasses.paper}>
          <div className={commonFormClasses.titleContainer}>{Translation('event.form.event_basic_info')}</div>
          <div className={commonFormClasses.contentContainer}>
            <div className={commonFormClasses.singleFieldPadding}>
              <FormControl
                fullWidth
                disabled={viewMode || disableEdit}
                variant="outlined"
                error={errorState.mandatory.type}
              >
                <FormLabel className={commonFormClasses.formLabel}>{Translation('common.type')}</FormLabel>
                <Select
                  labelId="type-label"
                  displayEmpty
                  size="medium"
                  value={formState.type || ''}
                  renderValue={(selected) =>
                    selected ? (
                      Translation(`event.form.event_type.${selected.toLowerCase()}`)
                    ) : (
                      <span className={commonFormClasses.placeholder}>
                        {Translation('event.form.event_type')}
                        <span className={commonFormClasses.mandatory}>*</span>
                      </span>
                    )
                  }
                  onChange={(e) => {
                    const dismissFields = ['type'];
                    const newFormState: Partial<BasicsFormState> = {
                      type: e.target.value as EventTypeEnum,
                    };
                    if (e.target.value === EventTypeEnum.VIRTUAL) {
                      regionLocale.forEach((locale) => {
                        dismissFields.push(`venue-${locale}`);
                      });
                      newFormState.venue = initI18nGenericData<string>(regionLocale, 'Online');
                    }
                    onDismissErrorHandler(dismissFields, true);
                    formDispatch({
                      type: 'SET_FORM_STATE',
                      payload: {
                        value: newFormState,
                      },
                    });
                  }}
                >
                  {map(EventTypeEnum, (option) => (
                    <MenuItem key={option} value={option}>
                      {Translation(`event.form.event_type.${option.toLowerCase()}`)}
                    </MenuItem>
                  ))}
                </Select>
                {errorState.mandatory.type && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
              </FormControl>
            </div>
            <MultiLangSection
              disabled={viewMode || disableEdit}
              fillInOnChange={(locale) => {
                onDismissErrorHandler([`name-${locale}`, `venue-${locale}`], true);
                formDispatch({
                  type: 'FILL_IN_DETAIL',
                  payload: { fromLocale: regionLocale[0], toLocale: locale },
                });
              }}
              renderChildren={(locale) => (
                <>
                  <div>
                    <TextField
                      disabled={viewMode || disableEdit}
                      fullWidth
                      size="medium"
                      variant="outlined"
                      label={
                        <>
                          {Translation('event.common.name')}
                          <span className={commonFormClasses.mandatory}>*</span>
                        </>
                      }
                      placeholder={Translation('event.form.name.placeholder')}
                      inputProps={{ maxLength: 80 }}
                      error={errorState.mandatory[`name-${locale}`]}
                      helperText={errorState.mandatory[`name-${locale}`] && MANDATORY_FIELD_ERROR_TEXT}
                      value={formState.name[locale] || ''}
                      onChange={(e) => {
                        onDismissErrorHandler(`name-${locale}`, e.target.value);
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: { field: 'name', value: { ...formState.name, [locale]: e.target.value } },
                        });
                      }}
                    />
                  </div>
                  <div className={commonFormClasses.singleFieldPadding}>
                    <TextField
                      disabled={viewMode || disableEdit}
                      fullWidth
                      size="medium"
                      variant="outlined"
                      label={Translation('event.form.event_organizer')}
                      placeholder={Translation('event.form.event_organizer.placeholder')}
                      value={formState.organizer[locale] || ''}
                      onChange={(e) => {
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: {
                            field: 'organizer',
                            value: { ...formState.organizer, [locale]: e.target.value },
                          },
                        });
                      }}
                    />
                  </div>
                  <div>
                    <TextField
                      disabled={viewMode || disableEdit || formState.type === EventTypeEnum.VIRTUAL}
                      fullWidth
                      size="medium"
                      variant="outlined"
                      label={
                        <>
                          {Translation('event.list.detail.venue')}
                          <span className={commonFormClasses.mandatory}>*</span>
                        </>
                      }
                      placeholder={Translation('event.form.venue.placeholder')}
                      error={errorState.mandatory[`venue-${locale}`]}
                      helperText={errorState.mandatory[`venue-${locale}`] && MANDATORY_FIELD_ERROR_TEXT}
                      value={formState.venue[locale] || ''}
                      onChange={(e) => {
                        onDismissErrorHandler(`venue-${locale}`, e.target.value);
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: { field: 'venue', value: { ...formState.venue, [locale]: e.target.value } },
                        });
                      }}
                    />
                  </div>
                  <div>
                    <Autocomplete
                      disabled={viewMode || disableEdit}
                      multiple
                      freeSolo
                      filterSelectedOptions
                      disablePortal
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'flip',
                              enabled: false,
                            },
                            {
                              name: 'preventOverflow',
                              enabled: false,
                            },
                          ],
                        },
                      }}
                      options={eventTagList.map((tagItem) => tagItem.name)}
                      renderTags={(value: readonly string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                          <Chip variant="filled" label={option} {...getTagProps({ index })} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="medium"
                          variant="outlined"
                          label={Translation('event.list.detail.tags')}
                          placeholder={Translation('event.list.detail.tags.placeholder')}
                        />
                      )}
                      value={formState.tags[locale] || []}
                      onChange={(_, newValue) => {
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: { field: 'tags', value: { ...formState.tags, [locale]: newValue } },
                        });
                      }}
                    />
                  </div>
                </>
              )}
            />
            {isVirtual && (
              <div>
                <FormLabel
                  disabled={viewMode || disableEdit}
                  className={commonFormClasses.formLabel}
                  error={errorState.immediate.onlineMeetingLinkFormat}
                >
                  {Translation('event.form.online_meeting_link')}
                </FormLabel>
                <div className={commonFormClasses.smallGapContentContainer}>
                  <div>
                    <TextField
                      disabled={viewMode || disableEdit}
                      fullWidth
                      size="medium"
                      variant="outlined"
                      label={Translation('event.form.online_meeting_link')}
                      placeholder={Translation('event.form.online_meeting_link.placeholder')}
                      error={errorState.immediate.onlineMeetingLinkFormat}
                      helperText={
                        errorState.immediate.onlineMeetingLinkFormat && Translation('component.form-link-text')
                      }
                      value={formState.onlineMeetingLink || ''}
                      onChange={(e) => {
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: { field: 'onlineMeetingLink', value: e.target.value },
                        });
                      }}
                    />
                    <div className={commonFormClasses.remark}>
                      {Translation('event.form.online_meeting_link.remark')}
                    </div>
                  </div>
                  <FormControl fullWidth disabled={viewMode || disableEdit}>
                    <FormLabel className={commonFormClasses.formLabelMargin}>
                      {Translation('event.form.online_meeting_link_display')}
                    </FormLabel>
                    <FormGroup className={commonFormClasses.radioGroup}>
                      {map(OnlineMeetingLinkDisplayEnum, (option) => (
                        <FormControlLabel
                          key={`onlineMeetingLinkDisplay-${option}`}
                          className={commonFormClasses.formControlLabel}
                          control={
                            <Checkbox
                              checked={!!formState.onlineMeetingLinkDisplay?.includes(option)}
                              onChange={(e) =>
                                formDispatch({
                                  type: 'MODIFY_FIELD',
                                  payload: {
                                    field: 'onlineMeetingLinkDisplay',
                                    value: e.target.checked
                                      ? [...(formState.onlineMeetingLinkDisplay || []), option]
                                      : formState.onlineMeetingLinkDisplay?.filter((item) => item !== option),
                                  },
                                })
                              }
                            />
                          }
                          label={
                            <span className={commonFormClasses.formLabelContainer}>
                              {Translation(`event.form.online_meeting_link_display.${option.toLowerCase()}`)}
                              <Tooltip
                                arrow
                                placement="bottom"
                                title={Translation(
                                  `event.form.online_meeting_link_display.${option.toLowerCase()}.tooltip`,
                                )}
                              >
                                <IconButton size={'small'} className={commonFormClasses.infoIcon}>
                                  <Info />
                                </IconButton>
                              </Tooltip>
                            </span>
                          }
                          labelPlacement="end"
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </div>
              </div>
            )}
            <div>
              <FormLabel
                disabled={viewMode || disableEdit}
                className={commonFormClasses.formLabel}
                error={
                  errorState.mandatory.eventDate ||
                  errorState.mandatory.startTime ||
                  errorState.mandatory.endTime ||
                  errorState.immediate.endTimeBeforeStartTime
                }
              >
                {Translation('common.time')}
              </FormLabel>
              <div className={classes.dateTimePickerContainer}>
                <FormControl
                  disabled={viewMode || disableEdit}
                  className={commonFormClasses.dateTimePickerField}
                  error={errorState.mandatory.eventDate}
                >
                  <PruDatePicker
                    disablePast
                    disabled={viewMode || disableEdit}
                    label={
                      <>
                        {Translation('event.common.event_date')}
                        <span className={commonFormClasses.mandatory}>*</span>
                      </>
                    }
                    slotProps={{
                      textField: {
                        size: 'medium',
                        variant: 'outlined',
                        error: errorState.mandatory.eventDate,
                        helperText: errorState.mandatory.eventDate && MANDATORY_FIELD_ERROR_TEXT,
                      },
                    }}
                    format="DD/MM/YYYY"
                    value={formState.eventDate}
                    onChange={(date) => {
                      onDismissErrorHandler('eventDate', date);
                      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'eventDate', value: date } });
                    }}
                  />
                </FormControl>
                <div className={commonFormClasses.doublePickerContainer}>
                  <FormControl
                    disabled={viewMode || disableEdit}
                    className={commonFormClasses.dateTimePickerField}
                    error={errorState.mandatory.startTime || errorState.immediate.endTimeBeforeStartTime}
                  >
                    <PruTimePicker
                      disablePast={moment(formState.eventDate).isSame(moment(), 'day')}
                      disabled={viewMode || disableEdit}
                      ampm={false}
                      label={
                        <>
                          {Translation('common.start_time')}
                          <span className={commonFormClasses.mandatory}>*</span>
                        </>
                      }
                      slotProps={{
                        textField: {
                          size: 'medium',
                          variant: 'outlined',
                          error: errorState.mandatory.startTime || errorState.immediate.endTimeBeforeStartTime,
                          helperText: errorState.mandatory.startTime && MANDATORY_FIELD_ERROR_TEXT,
                        },
                      }}
                      value={formState.startTime}
                      onChange={(date) => {
                        onDismissErrorHandler('startTime', date);
                        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'startTime', value: date } });
                      }}
                    />
                  </FormControl>
                  <div>{Translation('common.to')}</div>
                  <FormControl
                    disabled={viewMode || disableEdit}
                    className={commonFormClasses.dateTimePickerField}
                    error={errorState.mandatory.endTime || errorState.immediate.endTimeBeforeStartTime}
                  >
                    <PruTimePicker
                      disablePast={moment(formState.eventDate).isSame(moment(), 'day')}
                      disabled={viewMode || disableEdit}
                      ampm={false}
                      label={
                        <>
                          {Translation('common.end_time')}
                          <span className={commonFormClasses.mandatory}>*</span>
                        </>
                      }
                      slotProps={{
                        textField: {
                          size: 'medium',
                          variant: 'outlined',
                          error: errorState.mandatory.endTime || errorState.immediate.endTimeBeforeStartTime,
                          helperText: errorState.immediate.endTimeBeforeStartTime
                            ? TIME_ERROR_TEXT
                            : errorState.mandatory.endTime && MANDATORY_FIELD_ERROR_TEXT,
                        },
                      }}
                      value={formState.endTime}
                      onChange={(date) => {
                        onDismissErrorHandler('endTime', date);
                        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'endTime', value: date } });
                      }}
                    />
                  </FormControl>
                </div>
              </div>
            </div>
            <div>
              <FormControl
                fullWidth
                disabled={viewMode || isPublished || disableEdit}
                error={errorState.mandatory.multiSession}
              >
                <FormLabel className={commonFormClasses.formLabel}>
                  {Translation('event.form.multiple_sessions')}
                  <span className={commonFormClasses.mandatory}>*</span>
                  <Tooltip arrow placement="top" title={Translation('event.form.multiple_sessions.tooltip')}>
                    <IconButton size={'small'} className={commonFormClasses.infoIcon}>
                      <Info />
                    </IconButton>
                  </Tooltip>
                </FormLabel>
                <RadioGroup
                  className={commonFormClasses.radioGroup}
                  value={formState.multiSession !== undefined ? formState.multiSession.toString() : ''}
                  onChange={(e) => {
                    const dismissFields = ['multiSession'];
                    const newFormState: Partial<BasicsFormState> = {
                      multiSession: e.target.value === 'true' ? true : false,
                    };
                    if (e.target.value === 'true') {
                      dismissFields.push('quota');
                      newFormState.quota = undefined;
                      newFormState.regAllowWalkIn = null;
                    } else {
                      dismissFields.push('sessionEnrollment');
                      newFormState.sessionEnrollment = undefined;
                      newFormState.sessions = [];
                    }
                    onDismissErrorHandler(dismissFields, true);
                    formDispatch({
                      type: 'SET_FORM_STATE',
                      payload: {
                        value: newFormState,
                      },
                    });
                  }}
                >
                  {trueFalseOptions.map((option) => (
                    <FormControlLabel
                      key={option}
                      className={commonFormClasses.formControlLabel}
                      control={<Radio />}
                      label={Translation(`event.form.multiple_sessions.${option}`)}
                      value={option}
                    />
                  ))}
                </RadioGroup>
                {errorState.mandatory.multiSession && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
              </FormControl>
            </div>
            {formState.multiSession === false && (
              <div className={commonFormClasses.singleFieldPadding}>
                <FormControl
                  fullWidth
                  disabled={viewMode || isPublished || disableEdit}
                  error={errorState.mandatory.quota || errorState.immediate.quotaFormat}
                >
                  <FormLabel className={commonFormClasses.formLabel}>
                    {Translation('event.form.event_quota')}
                    <span className={commonFormClasses.mandatory}>*</span>
                  </FormLabel>
                  <TextField
                    disabled={viewMode || isPublished || disableEdit}
                    size="medium"
                    variant="outlined"
                    error={errorState.mandatory.quota || errorState.immediate.quotaFormat}
                    helperText={
                      errorState.immediate.quotaFormat
                        ? NUMBER_NA_ONLY_ERROR_TEXT
                        : errorState.mandatory.quota && MANDATORY_FIELD_ERROR_TEXT
                    }
                    value={formState.quota || ''}
                    onChange={(e) => {
                      onDismissErrorHandler('quota', e.target.value);
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: { field: 'quota', value: e.target.value },
                      });
                    }}
                  />
                  <div className={`${commonFormClasses.remark} ${commonFormClasses.remarkMargin}`}>
                    {Translation('event.form.event_quota.placeholder')}
                  </div>
                </FormControl>
              </div>
            )}
          </div>
        </Paper>
        {formState.multiSession === true && (
          <SessionSetting
            viewMode={viewMode}
            isPublished={isPublished}
            disableEdit={disableEdit}
            isVirtual={isVirtual}
            formState={formState}
            errorState={errorState}
            formDispatch={formDispatch}
            onDismissErrorHandler={onDismissErrorHandler}
          />
        )}
      </div>
    </>
  );
};

import { useEffect, useReducer, Dispatch } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { regionLocale, initI18nGenericData, RegionLocale } from 'src/app/i18n';
import { useErrorHandler, ErrorFieldType, ErrorFieldDef } from 'src/app/common/utils';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { SessionTypeEnum, EventSessionItem } from 'src/app/modules/event-v2/types';
import {
  BasicsFormAction,
  SessionFormState,
  sessionFormReducer,
} from 'src/app/modules/event-v2/pages/event-list/form/reducers';
import {
  checkInvalidQuotaFormat,
  checkInvalidStartTimeEndTime,
} from 'src/app/modules/event-v2/pages/event-list/form/common';

interface HookProps {
  isVirtual: boolean;
  sessionNum: number;
  sessionItem?: EventSessionItem;
  updateBasicsForm: Dispatch<BasicsFormAction>;
  onClose: () => void;
}

const detailToStateConvertor = (
  regionLocale: RegionLocale[],
  sessionNum: number,
  sessionItem?: EventSessionItem | SessionFormState,
): SessionFormState => {
  const initI18nStringData = initI18nGenericData<string>(regionLocale, '');
  return sessionItem
    ? {
        key: sessionItem.key,
        type: sessionItem.type,
        startTime: sessionItem.startTime,
        endTime: sessionItem.endTime,
        name: sessionItem.name,
        venue: sessionItem.venue,
        onlineMeetingLink: sessionItem.onlineMeetingLink,
        onlineMeetingLinkDisplay: sessionItem.onlineMeetingLinkDisplay,
        multiSubSession: sessionItem.multiSubSession,
        quota: sessionItem.quota,
        subSessions: sessionItem.subSessions,
        subSessionEnrollment: sessionItem.subSessionEnrollment,
      }
    : {
        key: `session${sessionNum}`,
        type: SessionTypeEnum.SESSION,
        startTime: null,
        endTime: null,
        name: initI18nStringData,
        venue: initI18nStringData,
        onlineMeetingLink: undefined,
        onlineMeetingLinkDisplay: undefined,
        multiSubSession: undefined,
        quota: undefined,
        subSessions: [],
        subSessionEnrollment: undefined,
      };
};

const getErrorFieldDef = ({
  formState,
  isVirtual,
  errorNameSuffix,
  quotaEnabled,
  isSubSession,
}: {
  formState: SessionFormState;
  isVirtual: boolean;
  errorNameSuffix: string;
  quotaEnabled: boolean;
  isSubSession?: boolean;
}) => {
  return [
    {
      name: `startTime${errorNameSuffix}`,
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => !!!formState.startTime,
    },
    {
      name: `endTime${errorNameSuffix}`,
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => !!!formState.endTime,
    },
    {
      name: `endTimeBeforeStartTime${errorNameSuffix}`,
      fieldType: ErrorFieldType.IMMEDIATE,
      condition: () => {
        if (formState.startTime && formState.endTime) {
          return !!moment(new Date(formState.startTime)).isSameOrAfter(moment(new Date(formState.endTime)));
        } else {
          return false;
        }
      },
    },
    {
      name: `onlineMeetingLinkFormat${errorNameSuffix}`,
      fieldType: ErrorFieldType.IMMEDIATE,
      condition: () =>
        isVirtual &&
        !!formState.onlineMeetingLink &&
        !(formState.onlineMeetingLink.startsWith('https://') || formState.onlineMeetingLink.startsWith('http://')),
    },
    {
      name: `quota${errorNameSuffix}`,
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => quotaEnabled && !!!formState.quota,
    },
    {
      name: `quotaFormat${errorNameSuffix}`,
      fieldType: ErrorFieldType.IMMEDIATE,
      condition: () => quotaEnabled && checkInvalidQuotaFormat(formState.quota),
    },
    ...(() => {
      let errorFieldDef: ErrorFieldDef[] = [];
      regionLocale.forEach((locale) => {
        errorFieldDef = [
          ...errorFieldDef,
          {
            name: `name-${locale}${errorNameSuffix}`,
            fieldType: ErrorFieldType.MANDATORY,
            condition: () => !!!formState.name[locale],
          },
        ];
      });
      if (!isSubSession) {
        errorFieldDef = [
          ...errorFieldDef,
          {
            name: 'multiSubSession',
            fieldType: ErrorFieldType.MANDATORY,
          },
          {
            name: 'subSessionEnrollment',
            fieldType: ErrorFieldType.MANDATORY,
            condition: () =>
              formState.multiSubSession === true &&
              formState.subSessions.length > 0 &&
              !!!formState.subSessionEnrollment,
          },
        ];
      }
      formState.subSessions.forEach((subSessionItem, index) => {
        errorFieldDef = [
          ...errorFieldDef,
          ...getErrorFieldDef({
            formState: subSessionItem,
            isVirtual,
            errorNameSuffix: `-sub${index + 1}`,
            quotaEnabled: true,
            isSubSession: true,
          }),
        ];
      });
      return errorFieldDef;
    })(),
  ];
};

export const useSessionForm = ({ isVirtual, sessionNum, sessionItem, updateBasicsForm, onClose }: HookProps) => {
  const dispatch = useDispatch();
  const [formState, formDispatch] = useReducer(
    sessionFormReducer,
    detailToStateConvertor(regionLocale, sessionNum, sessionItem),
  );

  const { errorState, onSubmitErrorValidator, immediateErrorValidator, onDismissErrorHandler } = useErrorHandler(
    formState,
    getErrorFieldDef({ formState, isVirtual, errorNameSuffix: '', quotaEnabled: formState.multiSubSession === false }),
  );

  useEffect(() => {
    immediateErrorValidator();
  }, [
    formState.startTime,
    formState.endTime,
    formState.quota,
    formState.subSessions,
    isVirtual,
    formState.onlineMeetingLink,
  ]);

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      if (formState.multiSubSession && formState.subSessions.length === 0) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.WARNING,
              content: 'Please set up at least one sub session.',
            },
          ]),
        );
      } else if (
        formState.multiSubSession &&
        formState.subSessions.length > 0 &&
        checkInvalidStartTimeEndTime(
          formState.startTime as Date,
          formState.endTime as Date,
          formState.subSessions as EventSessionItem[],
        )
      ) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              content: 'Sub session start time and end time should be within session start time and end time.',
            },
          ]),
        );
      } else {
        if (sessionItem) {
          updateBasicsForm({
            type: 'MODIFY_SESSION',
            payload: {
              index: sessionNum - 1,
              value: formState as EventSessionItem,
            },
          });
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                content: 'Edit session successfully!',
              },
            ]),
          );
        } else {
          updateBasicsForm({
            type: 'ADD_SESSION',
            payload: {
              value: formState as EventSessionItem,
            },
          });
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                content: 'Add session successfully!',
              },
            ]),
          );
        }
        onClose();
      }
    }
  };

  return {
    formState,
    errorState,
    formDispatch,
    onDismissErrorHandler,
    onSubmit,
  };
};

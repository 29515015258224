import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { takeUIScreenViewEvent } from 'src/app/common/ga/ga';
import { eventDetailWalkInFormGAData } from 'src/app/common/ga/types/ga-event';
import { StepStatusEnum, ComponentProps } from 'src/app/common/components/pru-stepped-form-agent';
import { EventFormCommonProps } from 'src/app/modules/event-v2/types';
import { CreateUpdateEventBody, modifyEventItem } from 'src/app/modules/event-v2/network';

type HookProps = ComponentProps<EventFormCommonProps>;

export const useWalkInForm = ({ formCommonProps, onStepChange }: HookProps) => {
  const dispatch = useDispatch();
  const { eventItem, reloadData } = formCommonProps;
  const walkInFormRef = useRef<any>();

  useEffect(() => {
    takeUIScreenViewEvent(eventDetailWalkInFormGAData);
  }, []);

  const handleSave = async () => {
    if (walkInFormRef.current) {
      walkInFormRef.current?.handleSave();
    }
  };

  const onSubmit = async (data: any) => {
    const body: CreateUpdateEventBody = {
      walkInFormBody: data.content,
    };
    try {
      if (eventItem) {
        await modifyEventItem(eventItem._id, body, dispatch);
        await reloadData();
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: 'Data saved successfully',
            },
          ]),
        );
        onStepChange({
          newActiveStep: { stepIndex: 5 },
          currentStepStatus: StepStatusEnum.FINISHED,
          forceNavigation: true,
          saveChanges: true,
        });
      }
    } catch (err) {}
  };

  return {
    walkInFormRef,
    handleSave,
    onSubmit,
  };
};

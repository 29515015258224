import { GAData } from '../redux/gaSlice';
import { TakeUIClickEventParameters } from './ga-general-type';

// screen
export const eventListGAData: GAData = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'view_event_list',
  stage: 'event_list_landing',
  screen_name: 'Event List',
};

export const eventDetailBasicsGAData: GAData = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'event_basics_setting_landing',
  screen_name: 'Event Detail',
};

export const eventDetailDetailsGAData: GAData = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'event_details_setting_landing',
  screen_name: 'Event Detail',
};

export const eventDetailRsvpGAData: GAData = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'event_rsvp_setting_landing',
  screen_name: 'Event Detail',
};

export const eventDetailRegFormGAData: GAData = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'registration_form_setting_landing',
  screen_name: 'Event Detail',
};

export const eventDetailWalkInFormGAData: GAData = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'walk_in_form_setting_landing',
  screen_name: 'Event Detail',
};

export const eventDetailPublishGAData: GAData = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'publish_setting_landing',
  screen_name: 'Event Detail',
};

export const eventDetailAssistantGAData: GAData = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'assistant_setting',
  stage: 'assistant_setting_landing',
  screen_name: 'Event Detail',
};

export const eventDetailParticipantGAData: GAData = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'participant_management',
  stage: 'participant_list_landing',
  screen_name: 'Event Detail',
};

export const eventDetailParticipantDetailGAData: GAData = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'participant_management',
  stage: 'participant_details_landing',
  screen_name: 'Event Detail',
};

// click event
export const eventListSearchClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'view_event_list',
  stage: 'search_event',
  screen_name: 'Event List',
  object_name: 'Search',
  object_type: 'Button',
};

export const eventListFilterClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'view_event_list',
  stage: 'filter_event',
  screen_name: 'Event List',
  object_name: 'Filter',
  object_type: 'Icon',
};

export const eventListAddEventClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'add_an_event',
  screen_name: 'Event List',
  object_name: 'Add New',
  object_type: 'Button',
};

export const eventListDefineCategoryClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'define_category',
  screen_name: 'Event List',
  object_name: 'Next',
  object_type: 'Button',
};

export const eventDetailSessionConfirmClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'set_up_event_session',
  screen_name: 'Event Detail',
  object_name: 'Confirm',
  object_type: 'Button',
};

export const eventDetailToEventDetailsSaveClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'proceed_to_event_details_setting',
  screen_name: 'Event Detail',
  object_name: 'Save & Continue',
  object_type: 'Button',
};

export const eventDetailDiscardClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'discard',
  screen_name: 'Event Detail',
  object_name: 'Discard',
  object_type: 'Button',
};

export const eventDetailSelectTemplateClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'select_event_image_template',
  screen_name: 'Event Detail',
  object_name: 'Company Offered Template',
  object_type: 'Checkbox',
};

export const eventDetailDownloadTemplateClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'download_event_details_template',
  screen_name: 'Event Detail',
  object_name: 'Download',
  object_type: 'Icon',
};

export const eventDetailToRsvpSaveClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'proceed_to_rsvp_setting',
  screen_name: 'Event Detail',
  object_name: 'Save & Continue',
  object_type: 'Button',
};

export const eventDetailToRegFormSaveClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'proceed_to_registration_form_setting',
  screen_name: 'Event Detail',
  object_name: 'Save & Continue',
  object_type: 'Button',
};

export const eventDetailToWalkInFormSaveClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'proceed_to_walk_in_form_setting',
  screen_name: 'Event Detail',
  object_name: 'Save & Continue',
  object_type: 'Button',
};

export const eventDetailToPublishSaveClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'proceed_to_publish_setting',
  screen_name: 'Event Detail',
  object_name: 'Save & Continue',
  object_type: 'Button',
};

export const eventDetailPublishSelectAgentClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'select_publishing_agent_scope',
  screen_name: 'Event Detail',
  object_name: 'Import',
  object_type: 'Button',
};

export const eventDetailPublishImportAgentClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'import_publishing_agent_scope',
  screen_name: 'Event Detail',
  object_name: 'Import',
  object_type: 'Button',
};

export const eventDetailSaveAsDraftClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'save_as_draft',
  screen_name: 'Event Detail',
  object_name: 'Save Draft',
  object_type: 'Button',
};

export const eventDetailPublishClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'publish_event',
  screen_name: 'Event Detail',
  object_name: 'Publish',
  object_type: 'Button',
};

export const eventListPublishClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'publish_event',
  screen_name: 'Event List',
  object_name: 'Publish',
  object_type: 'Button',
};

export const eventListDownloadQRCodeClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'event_set_up',
  stage: 'download_event_qr',
  screen_name: 'Event List',
  object_name: 'Download QR Code',
  object_type: 'Button',
};

export const eventListViewEventClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'edit_event',
  stage: 'view_event_details',
  screen_name: 'Event List',
  object_name: 'Event',
  object_type: 'Link',
};

export const eventDetailStepperClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'edit_event',
  stage: 'view_event_steppers',
  screen_name: 'Event Detail',
  object_name: 'Stepper',
  object_type: 'Button',
};

export const eventDetailModifyClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'edit_event',
  stage: 'modify_event',
  screen_name: 'Event Detail',
  object_name: 'Modify',
  object_type: 'Button',
};

export const eventDetailDeleteDraftClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'edit_event',
  stage: 'delete_event_draft',
  screen_name: 'Event Detail',
  object_name: 'Confirm',
  object_type: 'Button',
};

export const eventDetailCancelEventClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'edit_event',
  stage: 'cancel_event',
  screen_name: 'Event Detail',
  object_name: 'Cancel & Unpublish',
  object_type: 'Button',
};

export const eventDetailAssistantTabClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'assistant_setting',
  stage: 'view_assistants',
  screen_name: 'Event Detail',
  object_name: 'Assistant',
  object_type: 'Tab',
};

export const eventDetailAddAssistantClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'assistant_setting',
  stage: 'add_an_assistant',
  screen_name: 'Event Detail',
  object_name: 'Confirm',
  object_type: 'Button',
};

export const eventDetailEditAssistantClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'assistant_setting',
  stage: 'edit_an_assistant',
  screen_name: 'Event Detail',
  object_name: 'Edit',
  object_type: 'Button',
};

export const eventDetailRemoveAssistantClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'assistant_setting',
  stage: 'remove_an_assistant',
  screen_name: 'Event Detail',
  object_name: 'Remove',
  object_type: 'Button',
};

export const eventDetailSendAssistantClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'assistant_setting',
  stage: 'send_assistant_instruction',
  screen_name: 'Event Detail',
  object_name: 'Confirm',
  object_type: 'Button',
};

export const eventDetailParticipantTabClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'participant_management',
  stage: 'view_participants',
  screen_name: 'Event Detail',
  object_name: 'Participant',
  object_type: 'Tab',
};

export const eventDetailViewWalkInClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'participant_management',
  stage: 'view_walk_ins',
  screen_name: 'Event Detail',
  object_name: 'Walk-ins',
  object_type: 'Button',
};

export const eventDetailExportParticipantClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'participant_management',
  stage: 'export_participants',
  screen_name: 'Event Detail',
  object_name: 'Export',
  object_type: 'Button',
};

export const eventDetailExportResultClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'participant_management',
  stage: 'export_registration_form_result',
  screen_name: 'Event Detail',
  object_name: 'Registration Form Result',
  object_type: 'Button',
};

export const eventDetailViewParticipantClickGAData: TakeUIClickEventParameters = {
  module: 'Events',
  feature: 'Event Management',
  journey: 'participant_management',
  stage: 'view_participant_details',
  screen_name: 'Event Detail',
  object_name: 'Attendance',
  object_type: 'Button',
};

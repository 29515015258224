import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button, Dialog, DialogTitle, IconButton, Link } from '@mui/material';
import { Close } from '@mui/icons-material';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import { eventDetailPublishImportAgentClickGAData } from 'src/app/common/ga/types/ga-event';
import AsyncCsvLink from 'src/app/common/components/AsyncCsvLink';
import PruIcon from 'src/app/common/components/PruIcon';
import { UploadArea } from 'src/app/common/components/upload-area-agent';
import { downloadAgentListTemplate } from 'src/app/modules/event-v2/network';
import { useImportAgentFromFileDialog, HookProps } from './import-agent-from-file-dialog.hook';
import { useStyles } from './import-agent-from-file-dialog.style';

type ImportAgentFromFileDialogProps = HookProps & {
  open: boolean;
};

export const ImportAgentFromFileDialog: FC<ImportAgentFromFileDialogProps> = ({ open, onClose, onImportAgent }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const { classes } = useStyles();
  const { formState, errors, handleUploadFile, handleRemoveFile, handleImport } = useImportAgentFromFileDialog({
    onClose,
    onImportAgent,
  });

  return (
    <Dialog fullScreen open={open}>
      <DialogTitle className={classes.headerContainer}>
        <div className={classes.titleContainer}>
          <IconButton onClick={onClose}>
            <Close className={classes.closeIcon} />
          </IconButton>
          <span className={classes.title}>{Translation('common.add_from_list')}</span>
        </div>
        <div className={classes.buttonContainer}>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            {Translation('app.button.cancel')}
          </Button>
          <Button
            disabled={!formState.agentListFile || !formState.originalFile}
            variant="contained"
            color="secondary"
            onClick={async () => {
              takeUIClickEvent(eventDetailPublishImportAgentClickGAData);
              await handleImport();
            }}
          >
            {Translation('app.button.import')}
          </Button>
        </div>
      </DialogTitle>
      <div className={classes.contentContainer}>
        {!formState.agentListFile && (
          <div>
            <AsyncCsvLink
              isDisabled={false}
              filename={'agent-list-template.csv'}
              dataParser={(str) => str}
              asyncCall={() => downloadAgentListTemplate(dispatch)}
            >
              <div className={classes.downloadTemplateContainer}>
                <PruIcon icon="download" customClasses={classes.downloadIcon} />
                <Link className={classes.downloadText}>{Translation('common.download_the_template')}</Link>
              </div>
            </AsyncCsvLink>
          </div>
        )}
        <UploadArea
          id={`agent-list-file`}
          enableSuccessToast
          module="event"
          accessLevel="public"
          accept="text/csv"
          sources={formState.agentListFile ? [formState.agentListFile] : []}
          uploadText={Translation('common.upload_files')}
          onChange={handleUploadFile}
          onRemove={handleRemoveFile}
        />
        {errors.length > 0 && (
          <div>
            <div className={classes.errorTitle}>{Translation('common.import_agent_file_failed')}</div>
            <div className={classes.errorContainer}>
              {errors.map((error, index) => (
                <div key={index}>{error}</div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

import { I18nGenericData } from 'src/app/i18n';
import { AttachmentDef, FormMode } from 'src/app/common/types';
import { FormCommonProps } from 'src/app/common/components/pru-stepped-form-agent';
import {
  EventAccessibilityEnum,
  EventAudienceEnum,
  EventCategoryItem,
  EventCategoryMaterialItem,
} from './event-category-types';

export enum EventDetailPageTabEnum {
  APPLICATION = 'APPLICATION',
  ASSISTANT = 'ASSISTANT',
  PARTICIPANT = 'PARTICIPANT',
}

export enum EventStatusEnum {
  ONGOING = 'ONGOING',
  UPCOMING = 'UPCOMING',
  PAST = 'PAST',
  CANCELED = 'CANCELED',
}

export enum EventPublishStatusEnum {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export enum EventTypeEnum {
  PHYSICAL = 'PHYSICAL',
  VIRTUAL = 'VIRTUAL',
  HYBRID = 'HYBRID',
}

export enum OnlineMeetingLinkDisplayEnum {
  REGISTRANT_TICKET = 'REGISTRANT_TICKET',
  EVENT_DETAIL = 'EVENT_DETAIL',
}

export enum SessionEnrollmentEnum {
  ALL = 'ALL',
  SINGLE = 'SINGLE',
}

export enum EventImageTypeEnum {
  TEMPLATE = 'TEMPLATE',
  UPLOAD = 'UPLOAD',
}

export enum DescriptionTypeEnum {
  TEMPLATE = 'TEMPLATE',
  UPLOAD = 'UPLOAD',
  NO_TEMPLATE = 'NO_TEMPLATE',
}

export enum RegistrationMethodEnum {
  TEMPLATE = 'TEMPLATE',
  LINK = 'LINK',
}

export enum RegistrationNotificationMethodEnum {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
}

export enum RegistrationAttendanceRuleEnum {
  CHECK_IN = 'CHECK_IN',
  CHECK_IN_OUT = 'CHECK_IN_OUT',
}

export enum PublishAgentEnum {
  ALL = 'ALL',
  TARGET = 'TARGET',
}

export enum AgentForceEnrolEnum {
  NONE = 'NONE',
  PUBLISH_SETTING = 'PUBLISH_SETTING',
  TARGET = 'TARGET',
}

export enum SessionTypeEnum {
  SESSION = 'SESSION',
  SUB_SESSION = 'SUB_SESSION',
}

export enum AddTargetAgentsEnum {
  ADD_FROM_LIST = 'ADD_FROM_LIST',
  IMPORT_FROM_FILE = 'IMPORT_FROM_FILE',
}

export enum TargetAgentsByEnum {
  UPLOAD_AGENT_LIST = 'UPLOAD_AGENT_LIST',
}

export type EventFormCommonProps = FormCommonProps & {
  formMode: FormMode;
  isPublished: boolean;
  disableEdit: boolean;
  walkInAllowed: boolean;
  eventCategoryItem?: EventCategoryItem;
  eventTagList: EventTagItem[];
  eventItem?: EventItem;
  reloadData: (keepLoading?: boolean) => Promise<void>;
};

export type EventTagItem = {
  name: string;
};

export type EventSessionItem = {
  key: string;
  type: SessionTypeEnum;
  startTime: Date;
  endTime: Date;
  name: I18nGenericData<string>;
  venue: I18nGenericData<string>;
  onlineMeetingLink?: string;
  onlineMeetingLinkDisplay?: OnlineMeetingLinkDisplayEnum[];
  multiSubSession: boolean;
  quota?: string;
  subSessions: EventSessionItem[];
  subSessionEnrollment?: SessionEnrollmentEnum;
  regAttendanceRule?: RegistrationAttendanceRuleEnum;
  regAllowWalkIn?: boolean;
};

export type EventConfirmationBodyItem = {
  title: I18nGenericData<string>;
  body: I18nGenericData<string>;
};

export type EventAgentConfig = {
  targetAgentBy: TargetAgentsByEnum.UPLOAD_AGENT_LIST;
  eligibleDesignations: string[];
  eligibleZones: string[];
  eligibleAgents: string[];
  excludedAgents: string[];
  eligibleAgentListFile?: AttachmentDef | null;
  excludedAgentListFile?: AttachmentDef | null;
};

export type EventItem = {
  /* Basics starts */
  _id: string;
  category: EventCategoryItem;
  shareToPublic: boolean;
  agentRequired: boolean;
  type: EventTypeEnum;
  eventDate: Date;
  startTime: Date;
  endTime: Date;

  organizer?: I18nGenericData<string>;
  name: I18nGenericData<string>;
  venue: I18nGenericData<string>;
  tags?: I18nGenericData<string[]>;
  onlineMeetingLink?: string;
  onlineMeetingLinkDisplay?: OnlineMeetingLinkDisplayEnum[];

  /* Session starts */
  multiSession: boolean;
  sessions: EventSessionItem[];
  // multiSession true
  sessionEnrollment?: SessionEnrollmentEnum;
  // multiSession false
  quota?: string;
  /* Session ends */
  /* Basics ends */

  eventStatus: EventStatusEnum;
  publishStatus: EventPublishStatusEnum;

  /* Detail starts */
  eventImageType?: I18nGenericData<EventImageTypeEnum>;
  eventImage?: I18nGenericData<AttachmentDef>;
  selectedEventImage?: I18nGenericData<EventCategoryMaterialItem>;

  descriptionType?: I18nGenericData<DescriptionTypeEnum>;
  descriptionImage?: I18nGenericData<AttachmentDef>;

  description?: I18nGenericData<string>;
  attachments?: I18nGenericData<AttachmentDef[]>;
  /* Detail ends */

  /* Registration starts */
  regQuotaPerAgent?: number | null;
  regStartDate?: Date;
  regEndDate?: Date;
  regMethod?: RegistrationMethodEnum;
  regLink?: string;
  regNotificationMethod?: RegistrationNotificationMethodEnum;
  regConfirmationBody?: EventConfirmationBodyItem;
  regAttendanceRule?: RegistrationAttendanceRuleEnum;
  regAllowWalkIn?: boolean | null;
  /* Registration ends */

  /* Registration Form starts */
  regFormBody?: Record<string, any>;
  /* Registration Form ends */

  /* Walk in Form starts */
  walkInFormBody?: Record<string, any>;
  /* Walk in Form ends */

  /* Publishing starts */
  pubAgent?: PublishAgentEnum;
  pubAgentConfig?: EventAgentConfig;
  agentForceEnrol?: AgentForceEnrolEnum;
  agentForceEnrolConfig?: EventAgentConfig;
  /* Publishing ends */

  campaign?: string;
  createdBy: string;
  lastUpdatedBy: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type EnrollmentQuotaItem = {
  sessionKey: string;
  sessionName?: I18nGenericData<string>;
  registerNumber: number;
  quota: string;
};

export type EventListItem = {
  _id: string;
  name: I18nGenericData<string>;
  categoryName: I18nGenericData<string>;
  accessibility: EventAccessibilityEnum;
  audience: EventAudienceEnum;
  startTime: Date;
  endTime: Date;
  regStartDate?: Date;
  regEndDate?: Date;
  enrollmentAndQuota?: EnrollmentQuotaItem[];
  createdBy: string;
  lastUpdatedBy: string;
  lastUpdatedAt?: Date;
  eventStatus: EventStatusEnum;
  publishStatus: EventPublishStatusEnum;
};

export type SessionFormDialogState = {
  open: boolean;
  sessionNum?: number;
  sessionItem?: EventSessionItem;
};

export type EventMaterialListItem = {
  images: I18nGenericData<EventCategoryMaterialItem[]>;
  descriptions: I18nGenericData<EventCategoryMaterialItem[]>;
};

export type EventShortenCodeUrlItem = {
  shortenCodeUrl: string;
};

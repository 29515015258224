import { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { takeUIScreenViewEvent } from 'src/app/common/ga/ga';
import { eventListGAData } from 'src/app/common/ga/types/ga-event';
import { RequestFunction, ActionType } from 'src/app/common/components/ProTable';
import { FilterState } from 'src/app/common/components/ProTable/ProFilter';
import { convertSort } from 'src/app/common/components/ProTable/pro-table.utils';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { EventPublishStatusEnum, EventListItem, EventCategoryItem } from '../../../types';
import {
  EventListParam,
  fetchEventList,
  fetchEventCategoryList,
  fetchEventShortenCodeUrl,
  validateEventBeforePublish,
  modifyEventItem,
} from '../../../network';
import { getFilterConfig, getColumns, downloadQRCodePDF } from './utils';
import { eventListCacheParams } from '../event-list-routes';

export const useEventListingPage = ({
  cacheParams,
}: {
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
}) => {
  const locale = useLang();
  const dispatch = useDispatch();
  const { t: Translation } = useTranslation();
  const [eventCategoryList, setEventCategoryList] = useState<EventCategoryItem[]>([]);
  const [eventCategoryFilterList, setEventCategoryFilterList] = useState<EventCategoryItem[]>([]);
  const [keyword, setKeyword] = useState<string>('');
  const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);
  const actionRef = useRef<ActionType>();
  const agentInfo = useMemo(() => globalStore.getAgent(), []);

  const filterConfig = useMemo(
    () => getFilterConfig(Translation, locale, eventCategoryFilterList),
    [Translation, locale, eventCategoryFilterList],
  );

  const columns = useMemo(() => getColumns(Translation, locale, keyword), [Translation, locale, keyword]);

  const fetchCategory = async () => {
    try {
      const res = await fetchEventCategoryList({ pagination: false }, dispatch);
      setEventCategoryList(res.docs);
      const filterRes = await fetchEventCategoryList({ pagination: false, applicant: agentInfo?.agentCode }, dispatch);
      setEventCategoryFilterList(filterRes.docs);
    } catch (err) {}
  };

  useEffect(() => {
    takeUIScreenViewEvent(eventListGAData);
    fetchCategory();
    if (cacheParams) {
      // reset cacheParams
      cacheParams.current = { ...eventListCacheParams };
    }
    // eslint-disable-next-line
  }, []);

  const getEventList: RequestFunction<EventListItem, Record<string, any>> = async (params, sort, filter) => {
    const { keyword: search = '', page, pageSize: limit } = params;
    const { categories, accessibilities, audience, eventStatuses, publishStatuses } = filter;

    const listParams: EventListParam = {
      search,
      categories,
      accessibilities,
      audience,
      eventStatuses,
      publishStatuses,
      sort: Object.values(convertSort(sort))[0],
      page: page + 1,
      limit,
    };
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { docs, totalDocs, total, ...rest } = await fetchEventList(listParams, dispatch);
      return {
        success: true,
        data: docs,
        total: totalDocs,
        ...rest,
      };
    } catch (err) {
      return {
        success: false,
        data: [],
        total: 0,
      };
    }
  };

  const onFilterChange = (params: FilterState) => {
    const { keyword } = params.filterState;
    setKeyword(keyword);
  };

  const onPublish = async (eventListItem: EventListItem) => {
    try {
      await validateEventBeforePublish(eventListItem._id, dispatch);
      await modifyEventItem(eventListItem._id, { publishStatus: EventPublishStatusEnum.PUBLISHED }, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: 'Event published successfully',
          },
        ]),
      );
      actionRef?.current?.reload();
    } catch (err) {}
  };

  const onDownloadQRCode = async (eventListItem: EventListItem) => {
    try {
      const res = await fetchEventShortenCodeUrl(eventListItem._id, dispatch);
      await downloadQRCodePDF(res.shortenCodeUrl, eventListItem);
    } catch (err) {}
  };

  return {
    eventCategoryList,
    showCreateDialog,
    filterConfig,
    columns,
    actionRef,
    setShowCreateDialog,
    getEventList,
    onFilterChange,
    onPublish,
    onDownloadQRCode,
  };
};

import { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import { eventDetailAddAssistantClickGAData } from 'src/app/common/ga/types/ga-event';
import { MANDATORY_FIELD_ERROR_TEXT, EMAIL_FORMAT_ERROR_TEXT } from 'src/app/common/constants';
import { EventAssistantDialogState } from 'src/app/modules/event-v2/types';
import { useEventAssistantDialog } from './event-assistant-dialog.hook';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useParams } from 'react-router-dom';

type EventAssistantDialogProps = {
  dialogState: EventAssistantDialogState;
  onClose: () => void;
  refreshEventAssistantList: () => void;
};

export const EventAssistantDialog: FC<EventAssistantDialogProps> = ({
  dialogState,
  onClose,
  refreshEventAssistantList,
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { open, eventAssistantItem } = dialogState;

  const { formState, errorState, formDispatch, onDismissErrorHandler, onSubmit } = useEventAssistantDialog({
    eventId: id,
    eventAssistantItem,
    onClose,
    refreshEventAssistantList,
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
      <DialogTitle>
        {eventAssistantItem ? t('event.form.edit_assistant') : t('event.form.add_new_assistant')}
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          size="medium"
          color="secondary"
          required
          fullWidth
          label={t('event.form.assistant_name')}
          value={formState.name || ''}
          error={errorState.mandatory.name}
          helperText={errorState.mandatory.name && MANDATORY_FIELD_ERROR_TEXT}
          onChange={(e) => {
            onDismissErrorHandler('name', e.target.value);
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: { field: 'name', value: e.target.value },
            });
          }}
        />
        <TextField
          variant="outlined"
          size="medium"
          color="secondary"
          required
          fullWidth
          label={t('event.form.assistant_email_address')}
          error={errorState.immediate.emailFormat || errorState.mandatory.email}
          value={formState.email || ''}
          helperText={
            errorState.immediate.emailFormat
              ? EMAIL_FORMAT_ERROR_TEXT
              : errorState.mandatory.email && MANDATORY_FIELD_ERROR_TEXT
          }
          onChange={(e) => {
            onDismissErrorHandler('email', e.target.value);
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: { field: 'email', value: e.target.value },
            });
          }}
        />
      </DialogContent>
      <DialogActions style={{ paddingBottom: 20 }}>
        <Button variant="outlined" color="info" onClick={onClose}>
          {t('app.button.cancel')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            takeUIClickEvent(eventDetailAddAssistantClickGAData);
            onSubmit();
          }}
        >
          {t('app.button.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

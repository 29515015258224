import { FC, useRef } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { EVENT_BASE_PATH, EVENT_LIST_PATH } from '../../constants';
import { EventListingPage } from './list/event-listing-page.screen';
import { EventDetailPage } from './form/event-detail-page.screen';
import { ParticipantDetailComponent } from './form/components/participant/participant-detail/participant-detail.component';
import { EventDetailPageTabEnum } from '../../types';
import { defaultFilterState } from './form/components/participant/participant-list/participant-list.hook';

export const eventListPath = `${EVENT_BASE_PATH}${EVENT_LIST_PATH}`;

const EventListRoutes: FC = () => {
  const cacheParams = useRef({ ...eventListCacheParams });
  return (
    <Switch>
      <Redirect exact from={eventListPath} to={`${eventListPath}/list`} />
      <PruRoute
        exact
        path={`${eventListPath}/list`}
        render={(props) => <EventListingPage {...props} cacheParams={cacheParams} />}
      />
      <PruRoute exact path={`${eventListPath}/create/:categoryId`} component={EventDetailPage} />
      <PruRoute
        exact
        path={`${eventListPath}/edit/:id`}
        render={(props) => <EventDetailPage {...props} cacheParams={cacheParams} />}
      />
      <PruRoute
        exact
        path={`${eventListPath}/view/:id`}
        render={(props) => <EventDetailPage {...props} cacheParams={cacheParams} />}
      />
      <PruRoute exact path={`${eventListPath}/create`} component={EventDetailPage} />
      <PruRoute
        exact
        path={`${eventListPath}/view/:id/participant-details/:participantId`}
        component={ParticipantDetailComponent}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export const eventListCacheParams = {
  selectedTab: EventDetailPageTabEnum.APPLICATION,
  [EventDetailPageTabEnum.PARTICIPANT]: {
    filter: defaultFilterState,
  },
};

export default EventListRoutes;

import { I18nGenericData } from 'src/app/i18n';
import {
  EventTypeEnum,
  OnlineMeetingLinkDisplayEnum,
  SessionEnrollmentEnum,
  EventSessionItem,
} from 'src/app/modules/event-v2/types';

export type BasicsFormState = {
  disableSave: boolean;
  shareToPublic?: boolean | null;
  agentRequired?: boolean | null;
  type?: EventTypeEnum;
  eventDate: Date | null;
  startTime: Date | null;
  endTime: Date | null;

  organizer: I18nGenericData<string>;
  name: I18nGenericData<string>;
  venue: I18nGenericData<string>;
  tags: I18nGenericData<string[]>;
  onlineMeetingLink?: string;
  onlineMeetingLinkDisplay?: OnlineMeetingLinkDisplayEnum[];

  /* Session starts */
  multiSession?: boolean;
  sessions: EventSessionItem[];
  sessionEnrollment?: SessionEnrollmentEnum;
  quota?: string;
  /* Session ends */

  regQuotaPerAgent?: number | null;
  regAllowWalkIn?: boolean | null;
};

type SetFormStateAction = {
  type: 'SET_FORM_STATE';
  payload: {
    value: Partial<BasicsFormState>;
  };
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof BasicsFormState;
    value: any;
  };
};

type FillInDetailAction = {
  type: 'FILL_IN_DETAIL';
  payload: {
    fromLocale: string;
    toLocale: string;
  };
};

type AddSessionAction = {
  type: 'ADD_SESSION';
  payload: {
    value: EventSessionItem;
  };
};

type ModifySessionAction = {
  type: 'MODIFY_SESSION';
  payload: {
    index: number;
    value: EventSessionItem;
  };
};

type RemoveSessionAction = {
  type: 'REMOVE_SESSION';
  payload: {
    index: number;
  };
};

export type BasicsFormAction =
  | SetFormStateAction
  | ModifyFieldAction
  | FillInDetailAction
  | AddSessionAction
  | ModifySessionAction
  | RemoveSessionAction;

export const basicsFormReducer = (state: BasicsFormState, action: BasicsFormAction): BasicsFormState => {
  let newSessions = [...state.sessions];
  switch (action.type) {
    case 'SET_FORM_STATE':
      return {
        ...state,
        ...action.payload.value,
        disableSave: false,
      };
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
        disableSave: false,
      };
    case 'FILL_IN_DETAIL':
      const fromLocale = action.payload.fromLocale;
      const toLocale = action.payload.toLocale;
      return {
        ...state,
        organizer: {
          ...state.organizer,
          [toLocale]: state.organizer[fromLocale],
        },
        name: {
          ...state.name,
          [toLocale]: state.name[fromLocale],
        },
        venue: {
          ...state.venue,
          [toLocale]: state.venue[fromLocale],
        },
        tags: {
          ...state.tags,
          [toLocale]: state.tags[fromLocale],
        },
      };
    case 'ADD_SESSION':
      newSessions.push(action.payload.value);
      return {
        ...state,
        sessions: newSessions,
      };
    case 'MODIFY_SESSION':
      newSessions[action.payload.index] = action.payload.value;
      return {
        ...state,
        sessions: newSessions,
      };
    case 'REMOVE_SESSION':
      newSessions.splice(action.payload.index, 1);
      newSessions = newSessions.map((sessionItem, index) => ({
        ...sessionItem,
        key: `session${index + 1}`,
        subSessions: sessionItem.subSessions.map((subSessionItem, subIndex) => ({
          ...subSessionItem,
          key: `session${index + 1}_sub${subIndex + 1}`,
        })),
      }));
      return {
        ...state,
        sessions: newSessions,
      };
    default:
      return state;
  }
};

import { useState, useEffect, useReducer, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { regionLocale, initI18nGenericData, initI18nGenericDataWithFunction, RegionLocale } from 'src/app/i18n';
import { AttachmentDef, FormMode } from 'src/app/common/types';
import { useErrorHandler, dismissTinymceError, ErrorFieldType, ErrorFieldDef } from 'src/app/common/utils';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { takeUIScreenViewEvent } from 'src/app/common/ga/ga';
import { eventDetailDetailsGAData } from 'src/app/common/ga/types/ga-event';
import { StepStatusEnum, ComponentProps, useUpdateChecker } from 'src/app/common/components/pru-stepped-form-agent';
import {
  EventImageTypeEnum,
  DescriptionTypeEnum,
  EventFormCommonProps,
  EventItem,
  EventMaterialListItem,
  EventCategoryMaterialItem,
} from 'src/app/modules/event-v2/types';
import { CreateUpdateEventBody, fetchEventMaterialList, modifyEventItem } from 'src/app/modules/event-v2/network';
import { DetailsFormState, detailsFormReducer } from '../../../reducers';
import { checkUndefinedI18nData } from '../../../common';

type HookProps = ComponentProps<EventFormCommonProps>;

const initEventImageTypeValue = (
  eventImageTypeByLocale?: EventImageTypeEnum,
  materialListByLocale?: EventCategoryMaterialItem[],
) => {
  return (materialListByLocale?.length ?? 0) === 0
    ? EventImageTypeEnum.UPLOAD
    : eventImageTypeByLocale || EventImageTypeEnum.TEMPLATE;
};

const initSelectedEventImageValue = (
  selectedEventImageByLocale?: EventCategoryMaterialItem,
  materialListByLocale?: EventCategoryMaterialItem[],
) => {
  return !selectedEventImageByLocale ||
    (materialListByLocale?.length ?? 0) === 0 ||
    !materialListByLocale?.find((material) => material._id === selectedEventImageByLocale._id)
    ? ''
    : selectedEventImageByLocale._id;
};

const initDescriptionTypeValue = (
  descriptionTypeByLocale?: DescriptionTypeEnum,
  materialListByLocale?: EventCategoryMaterialItem[],
) => {
  return (!descriptionTypeByLocale || descriptionTypeByLocale === DescriptionTypeEnum.TEMPLATE) &&
    (materialListByLocale?.length ?? 0) === 0
    ? DescriptionTypeEnum.UPLOAD
    : descriptionTypeByLocale || DescriptionTypeEnum.TEMPLATE;
};

const detailToStateConvertor = (
  regionLocale: RegionLocale[],
  eventItem?: EventItem,
  eventMaterialListItem?: EventMaterialListItem,
): DetailsFormState => {
  const initI18nImageTypeData = initI18nGenericDataWithFunction<EventImageTypeEnum>(regionLocale, (locale) =>
    initEventImageTypeValue(eventItem?.eventImageType?.[locale], eventMaterialListItem?.images[locale]),
  );
  const initI18nSelectedEventImageData = initI18nGenericDataWithFunction<string>(regionLocale, (locale) =>
    initSelectedEventImageValue(eventItem?.selectedEventImage?.[locale], eventMaterialListItem?.images[locale]),
  );
  const initI18nDescriptionTypeData = initI18nGenericDataWithFunction<DescriptionTypeEnum>(regionLocale, (locale) =>
    initDescriptionTypeValue(eventItem?.descriptionType?.[locale], eventMaterialListItem?.descriptions[locale]),
  );
  const initI18nStringData = initI18nGenericData<string>(regionLocale, '');
  const initI18nFileData = initI18nGenericData<AttachmentDef>(regionLocale);
  const initI18nFileListData = initI18nGenericData<AttachmentDef[]>(regionLocale, []);

  return eventItem
    ? {
        eventImageType: initI18nImageTypeData,
        eventImage: eventItem.eventImage || initI18nFileData,
        selectedEventImage: initI18nSelectedEventImageData,
        descriptionType: initI18nDescriptionTypeData,
        descriptionImage: eventItem.descriptionImage || initI18nFileData,
        description: eventItem.description || initI18nStringData,
        attachments: eventItem.attachments || initI18nFileListData,
      }
    : {
        eventImageType: initI18nImageTypeData,
        eventImage: initI18nFileData,
        selectedEventImage: initI18nStringData,
        descriptionType: initI18nDescriptionTypeData,
        descriptionImage: initI18nFileData,
        description: initI18nStringData,
        attachments: initI18nFileListData,
      };
};

export const useDetailsForm = ({
  formCommonProps,
  onStepChange,
  componentUpdateStepStatus,
  setDiscardChanges,
}: HookProps) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id?: string }>();
  const { formMode, eventItem, isReset, setIsLoading, setChangesUnsaved, setIsReset, reloadData } = formCommonProps;
  const [eventMaterialListItem, setEventMaterialListItem] = useState<EventMaterialListItem>();
  const [disableUpdateChecker, setDisableUpdateChecker] = useState<boolean>(true);
  const [initialFormMode, setInitialFormMode] = useState<FormMode>();

  const initialState = useMemo(
    () => detailToStateConvertor(regionLocale, eventItem, eventMaterialListItem),
    [eventItem, eventMaterialListItem],
  );
  const [formState, formDispatch] = useReducer(detailsFormReducer, initialState);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, resetErrorState } = useErrorHandler(formState, [
    ...(() => {
      let errorFieldDef: ErrorFieldDef[] = [];
      regionLocale.forEach((locale) => {
        errorFieldDef = [
          ...errorFieldDef,
          {
            name: `selectedEventImage-${locale}`,
            fieldType: ErrorFieldType.MANDATORY,
            condition: () =>
              formState.eventImageType[locale] === EventImageTypeEnum.TEMPLATE &&
              !!!formState.selectedEventImage[locale],
          },
          {
            name: `eventImage-${locale}`,
            fieldType: ErrorFieldType.MANDATORY,
            condition: () =>
              formState.eventImageType[locale] === EventImageTypeEnum.UPLOAD && !!!formState.eventImage[locale],
          },
          {
            name: `descriptionImage-${locale}`,
            fieldType: ErrorFieldType.MANDATORY,
            condition: () =>
              formState.descriptionType[locale] !== DescriptionTypeEnum.NO_TEMPLATE &&
              !!!formState.descriptionImage[locale],
          },
          {
            name: `description-${locale}`,
            fieldType: ErrorFieldType.MANDATORY,
            condition: () =>
              formState.descriptionType[locale] === DescriptionTypeEnum.NO_TEMPLATE && !!!formState.description[locale],
          },
        ];
      });
      return errorFieldDef;
    })(),
  ]);

  const reloadEventMaterialList = async () => {
    if (id) {
      setIsLoading(true);
      try {
        const materialRes = await fetchEventMaterialList(id, dispatch);
        const imageMaterials = materialRes.images;
        const selectedEventImage = eventItem?.selectedEventImage;
        // Add deleted image in view mode
        if (formMode === FormMode.VIEW && selectedEventImage) {
          Object.keys(selectedEventImage).forEach((locale) => {
            const selectedEventImageByLocale = selectedEventImage[locale];
            if (selectedEventImageByLocale && selectedEventImageByLocale.isActive === false) {
              const newImageMaterialsByLocale = imageMaterials[locale] || [];
              newImageMaterialsByLocale.unshift(selectedEventImageByLocale);
              imageMaterials[locale] = newImageMaterialsByLocale;
            }
          });
        }
        setEventMaterialListItem({ ...materialRes, images: imageMaterials });
        if (formMode === FormMode.EDIT && disableUpdateChecker) {
          setDisableUpdateChecker(false);
        }
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const resetFormState = () => {
    formDispatch({
      type: 'SET_FORM_STATE',
      payload: {
        value: initialState,
      },
    });
    resetErrorState();
    componentUpdateStepStatus({
      newStatus: eventItem?.eventImageType ? StepStatusEnum.FINISHED : StepStatusEnum.PROGRESS,
      saveChanges: true,
      forceUpdateStatus: true,
    });
    setIsReset(true);
  };

  const removeDeletedImage = () => {
    // Remove deleted image when switch from view to edit mode
    if (eventItem && eventMaterialListItem) {
      const newImageMaterials = eventMaterialListItem.images;
      const selectedEventImage = eventItem.selectedEventImage;
      if (selectedEventImage) {
        Object.keys(selectedEventImage).forEach((locale) => {
          const selectedEventImageByLocale = selectedEventImage[locale];
          if (selectedEventImageByLocale && selectedEventImageByLocale.isActive === false) {
            newImageMaterials[locale] = newImageMaterials[locale]?.filter(
              (material) => material._id !== selectedEventImageByLocale._id,
            );
          }
        });
      }
      setEventMaterialListItem({ ...eventMaterialListItem, images: newImageMaterials });
      if (disableUpdateChecker) {
        setDisableUpdateChecker(false);
      }
    }
  };

  useEffect(() => {
    takeUIScreenViewEvent(eventDetailDetailsGAData);
  }, []);

  useEffect(() => {
    reloadEventMaterialList();
  }, [id]);

  useEffect(() => {
    if (eventItem) {
      formDispatch({
        type: 'SET_FORM_STATE',
        payload: {
          value: initialState,
        },
      });
    }
    setDiscardChanges(resetFormState);
    return () => {
      setDiscardChanges(undefined);
    };
  }, [initialState]);

  useEffect(() => {
    if (!initialFormMode) {
      setInitialFormMode(formMode);
    } else if (initialFormMode === FormMode.VIEW && formMode === FormMode.EDIT) {
      removeDeletedImage();
    }
  }, [formMode]);

  useEffect(() => {
    dismissTinymceError(formState, 'description', onDismissErrorHandler, regionLocale);
  }, [formState.description, regionLocale]);

  useUpdateChecker({ disabled: disableUpdateChecker, formState, isReset, setChangesUnsaved, setIsReset });

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      const body: CreateUpdateEventBody = {
        eventImageType: formState.eventImageType,
        eventImage: !checkUndefinedI18nData(formState.eventImage) ? formState.eventImage : undefined,
        selectedEventImage: formState.selectedEventImage,
        descriptionType: formState.descriptionType,
        descriptionImage: !checkUndefinedI18nData(formState.descriptionImage) ? formState.descriptionImage : undefined,
        description: formState.description,
        attachments: formState.attachments,
      };
      try {
        if (eventItem) {
          await modifyEventItem(eventItem._id, body, dispatch);
          await reloadData();
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: 'Data saved successfully',
              },
            ]),
          );
          onStepChange({
            newActiveStep: { stepIndex: 2 },
            currentStepStatus: StepStatusEnum.FINISHED,
            forceNavigation: true,
            saveChanges: true,
          });
        }
      } catch (err) {}
    } else {
      componentUpdateStepStatus({ newStatus: StepStatusEnum.WARNING });
    }
  };

  return {
    eventMaterialListItem,
    formState,
    errorState,
    formDispatch,
    onDismissErrorHandler,
    onSubmit,
  };
};

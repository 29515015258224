import React, { FC, useState, useRef, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { getApplicationDisplayDate } from '../../../utils/time-utils';
import ApplicationTag from './ApplicationTag';
import { toAbsoluteUrl } from 'src/app/common/utils';
import { PUBLIC_URL } from 'src/app/common/network';
import { nl2br } from '../../../../../common/utils/paragraph-utils';
import MoreLess from '../../../../../common/components/ShowMoreLess/MoreLess';
import { Application } from '../../../types/application-types';
import { fileSizeConvertorMb } from '../../../../../common/utils/common-utils';
import { useIntl } from 'react-intl';

const ITMS_URL = 'itms-services://?action=download-manifest&url=';

type ApplicationItemProps = {
  applicationItem: Application;
  onOpenLoginDialog: () => void;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    [theme.breakpoints.up('xs')]: {
      padding: '16px 16px 0 16px',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '12px 12px 0 12px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '16px 16px 0 16px',
    },
  },
  mobileHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  mobileFooter: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  iconContainer: {
    width: 60,
    height: 60,
    borderRadius: 12,
    marginRight: 12,
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  appname: {
    whiteSpace: 'nowrap',
    fontSize: '1.2rem',
    lineHeight: 1.5,
    fontWeight: 600,
    marginRight: 8,
  },
  appversion: {
    whiteSpace: 'nowrap',
    fontSize: '1.2rem',
    lineHeight: 1.5,
    fontWeight: 400,
    color: '#858585',
  },
  mobileDescription: {
    fontSize: 12,
    lineHeight: 1.5,
    fontWeight: 400,
    color: '#858585',
  },
  appdescription: {
    fontSize: 12,
    lineHeight: 1.5,
    fontWeight: 400,
    color: '#858585',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  downloadBtn: {
    border: '2px solid #333333',
    color: '#333333',
    fontSize: 11,
    padding: '5px 10px 5px 10px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
  },
  showMoreBtn: {
    fontWeight: 500,
    fontSize: 10,
    color: '#E8192C',
    cursor: 'pointer',
  },

  tabletRow: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #EEEEEE',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 12,
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: 16,
    },
  },
  tabletDetailGroup: {
    flexBasis: '25%',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      minWidth: 310,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 380,
    },
  },
  tabletDescriptionGroup: {
    flexBasis: '40%',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  mobileSection: {
    paddingBottom: 16,
    borderBottom: '1px solid #EEEEEE',
  },
  tabletBtnGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cover: {
    position: 'absolute',
    backgroundColor: 'black',
    opacity: 0.08,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    cursor: 'pointer',
    zIndex: 2,
  },
  lineClamp: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    lineClamp: 3,
    overflow: 'hidden',
  },
  noLineClamp: {
    display: 'block',
  },
}));

const ApplicationItem: FC<ApplicationItemProps> = ({ applicationItem, onOpenLoginDialog }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  //@ts-ignore
  const isWide = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const [isSeeMore, setIsSeeMore] = useState<boolean | undefined>();
  const tabletRef = useRef<HTMLDivElement>(null);
  const mobileRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isWide && tabletRef.current) {
      const lineClampDiv = tabletRef.current;
      if (lineClampDiv.scrollHeight > lineClampDiv.offsetHeight) {
        setIsSeeMore(false);
      }
    }
    if (!isWide && mobileRef.current) {
      const lineClampDiv = mobileRef.current;
      if (lineClampDiv.scrollHeight > lineClampDiv.offsetHeight) {
        setIsSeeMore(false);
      }
    }
  }, [isWide, tabletRef, mobileRef]);

  const fileSizeDescription = React.useMemo(() => {
    const iosFileSize = applicationItem.iosFileSize > 0 ? fileSizeConvertorMb(applicationItem.iosFileSize) : 0;
    const androidFileSize =
      applicationItem.androidFileSize > 0 ? fileSizeConvertorMb(applicationItem.androidFileSize) : 0;
    const iosFileSizeDescription = iosFileSize ? `iOS: ${iosFileSize}.` : '';
    const androidFileSizeDescription = androidFileSize ? `Android: ${androidFileSize}` : '';
    return `${iosFileSizeDescription} ${androidFileSizeDescription}`;
  }, [applicationItem]);

  return (
    <div className={classes.root}>
      {!applicationItem.allowAccess && <div className={classes.cover} onClick={onOpenLoginDialog} />}
      {isWide ? (
        <div className={classes.tabletRow}>
          <div className={classes.tabletDetailGroup}>
            <div className={classes.iconContainer}>
              <img src={applicationItem.iconUrl} alt={`${applicationItem.name} Icon`} />
            </div>
            <div>
              <div className={classes.rowContainer}>
                <div className={classes.appname}>{applicationItem.name}</div>
                <div style={{ marginRight: 8 }} className={classes.appversion}>
                  {applicationItem.targetVersion}
                </div>
                {applicationItem.tags.find((tag) => tag === 'Latest') && (
                  <ApplicationTag style={{ marginRight: 8 }} tag={'latest'} />
                )}
                {applicationItem.tags.find((tag) => tag === 'iPad') && (
                  <ApplicationTag style={{ marginRight: 8 }} tag={'ipad'} />
                )}
              </div>
              <div>
                <div>{`${Translation(getApplicationDisplayDate(applicationItem.updatedAt))}`}</div>
                <div>{fileSizeDescription}</div>
              </div>
            </div>
          </div>
          <div className={`${classes.appdescription} ${classes.tabletDescriptionGroup}`}>
            <div className={!isSeeMore ? classes.lineClamp : classes.noLineClamp} ref={tabletRef}>
              {nl2br(applicationItem.releaseNote)}
            </div>
            {isSeeMore !== undefined && (
              <div style={{ paddingTop: 5 }}>
                <MoreLess isSeeMore={isSeeMore} onClick={() => setIsSeeMore(!isSeeMore)} />
              </div>
            )}
          </div>
          <div style={{ flexBasis: '35%' }} className={classes.tabletBtnGroup}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {applicationItem.iosFileSize > 0 && (
                <a
                  className={classes.downloadBtn}
                  style={{ marginRight: 10, marginBottom: 8 }}
                  href={`${ITMS_URL}${PUBLIC_URL}/application/${applicationItem.id}/app.plist`}
                >
                  <img
                    style={{ marginRight: 10 }}
                    src={toAbsoluteUrl('/media/download-page/app_store.png')}
                    alt={'Apple Icon'}
                  />
                  {Translation('downloadPage.downloadIos')}
                </a>
              )}
              {applicationItem.androidFileSize > 0 && (
                <a
                  style={{ marginRight: 10, marginBottom: 8 }}
                  className={classes.downloadBtn}
                  href={applicationItem.androidDownloadUrl}
                >
                  <img
                    style={{ marginRight: 10 }}
                    src={toAbsoluteUrl('/media/download-page/android.png')}
                    alt={'Android Icon'}
                  />
                  {Translation('downloadPage.downloadAndroid')}
                </a>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.mobileSection}>
          <div className={classes.mobileHeader}>
            <div className={classes.iconContainer}>
              <img src={applicationItem.iconUrl} alt={`${applicationItem.name} Icon`} />
            </div>
            <div style={{ flexGrow: 1 }}>
              <div className={classes.rowContainer}>
                <div className={classes.appname}>{applicationItem.name}</div>
                <div style={{ marginRight: 8 }} className={classes.appversion}>
                  {applicationItem.targetVersion}
                </div>
                {applicationItem.tags.find((tag) => tag === 'Latest') && (
                  <ApplicationTag style={{ marginRight: 8 }} tag={'latest'} />
                )}
                {applicationItem.tags.find((tag) => tag === 'iPad') && (
                  <ApplicationTag style={{ marginRight: 8 }} tag={'ipad'} />
                )}
              </div>
              <div className={`${classes.rowContainer}`}>
                <div>{fileSizeDescription}</div>
              </div>
            </div>
          </div>
          <div className={classes.mobileDescription}>
            <div className={!isSeeMore ? classes.lineClamp : classes.noLineClamp} ref={mobileRef}>
              {nl2br(applicationItem.releaseNote)}
            </div>
            {isSeeMore !== undefined && (
              <div style={{ paddingTop: 5 }}>
                <MoreLess isSeeMore={isSeeMore} onClick={() => setIsSeeMore(!isSeeMore)} />
              </div>
            )}
          </div>
          <div className={classes.mobileFooter}>
            {applicationItem.iosFileSize > 0 && (
              <a
                className={classes.downloadBtn}
                style={{ marginRight: 10, marginTop: 8 }}
                href={`${ITMS_URL}${PUBLIC_URL}/application/${applicationItem.id}/app.plist`}
              >
                <img
                  style={{ marginRight: 10 }}
                  src={toAbsoluteUrl('/media/download-page/app_store.png')}
                  alt={'Apple Icon'}
                />
                {Translation('downloadPage.downloadIos')}
              </a>
            )}
            {applicationItem.androidFileSize > 0 && (
              <a style={{ marginTop: 8 }} className={classes.downloadBtn} href={applicationItem.androidDownloadUrl}>
                <img
                  style={{ marginRight: 10 }}
                  src={toAbsoluteUrl('/media/download-page/android.png')}
                  alt={'Android Icon'}
                />
                {Translation('downloadPage.downloadAndroid')}
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplicationItem;

import { FC } from 'react';
import moment from 'moment';
import { map } from 'lodash';
import { useIntl } from 'react-intl';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Paper,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import { regionLocale, useLang } from 'src/app/i18n';
import { MANDATORY_FIELD_ERROR_TEXT, NUMBER_ONLY_ERROR_TEXT, trueFalseOptions } from 'src/app/common/constants';
import { FormMode } from 'src/app/common/types';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import { eventDetailToRegFormSaveClickGAData } from 'src/app/common/ga/types/ga-event';
import { ComponentProps } from 'src/app/common/components/pru-stepped-form-agent';
import { PruDateTimePicker } from 'src/app/common/components/PruDatePicker';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import Tinymce from 'src/app/common/components/Tinymce-storage-blob';
import {
  EventAccessibilityEnum,
  RegistrationAttendanceRuleEnum,
  RegistrationNotificationMethodEnum,
  EventFormCommonProps,
} from 'src/app/modules/event-v2/types';
import { RegistrationAttendanceFormState } from '../../../reducers';
import { useCommonFormStyles, MultiLangAccordion, TopButton } from '../../../common';
import { useRegistrationAttendanceStyles } from './registration-attendance-form.style';
import { useRegistrationAttendanceForm } from './registration-attendance-form.hook';

type EventRegistrationAttendanceFormProps = ComponentProps<EventFormCommonProps>;

export const EventRegistrationAttendanceForm: FC<EventRegistrationAttendanceFormProps> = ({
  formCommonProps,
  ...rest
}) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const locale = useLang();
  const { classes } = useRegistrationAttendanceStyles();
  const { classes: commonFormClasses } = useCommonFormStyles();
  const { formMode, isPublished, disableEdit, eventItem } = formCommonProps;
  const { formState, errorState, initI18nStringData, formDispatch, onDismissErrorHandler, onSubmit } =
    useRegistrationAttendanceForm({
      formCommonProps,
      ...rest,
    });
  const viewMode = formMode === FormMode.CREATE || formMode === FormMode.VIEW;

  return (
    <>
      <TopButton
        formMode={formMode}
        eventItem={eventItem}
        onSave={() => {
          takeUIClickEvent(eventDetailToRegFormSaveClickGAData);
          onSubmit();
        }}
      />
      <div className={commonFormClasses.paperContainer}>
        <Paper className={commonFormClasses.paper}>
          <div className={commonFormClasses.titleContainer}>{Translation('event.form.registration')}</div>
          <div className={commonFormClasses.smallGapContentContainer}>
            {eventItem?.category.accessibility === EventAccessibilityEnum.PUBLIC && (
              <div>
                <TextField
                  disabled={viewMode || isPublished || disableEdit}
                  fullWidth
                  size="medium"
                  variant="outlined"
                  label={Translation('event.form.quota_per_agent')}
                  placeholder={Translation('event.form.quota_per_agent.placeholder')}
                  error={errorState.immediate.regQuotaPerAgentFormat}
                  helperText={errorState.immediate.regQuotaPerAgentFormat && NUMBER_ONLY_ERROR_TEXT}
                  value={formState.regQuotaPerAgent || ''}
                  onChange={(e) => {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'regQuotaPerAgent', value: e.target.value },
                    });
                  }}
                />
              </div>
            )}
            <div className={commonFormClasses.doublePickerContainer}>
              <FormControl
                disabled={viewMode || disableEdit}
                className={commonFormClasses.dateTimePickerField}
                error={errorState.mandatory.regStartDate || errorState.immediate.endDateBeforeStartDate}
              >
                <PruDateTimePicker
                  disablePast
                  disabled={viewMode || disableEdit}
                  ampm={false}
                  label={
                    <>
                      {Translation('event.form.registration_start_time')}
                      <span className={commonFormClasses.mandatory}>*</span>
                    </>
                  }
                  slotProps={{
                    textField: {
                      size: 'medium',
                      variant: 'outlined',
                      error: errorState.mandatory.regStartDate || errorState.immediate.endDateBeforeStartDate,
                      helperText: errorState.mandatory.regStartDate && MANDATORY_FIELD_ERROR_TEXT,
                    },
                  }}
                  format="DD/MM/YYYY HH:mm"
                  maxDateTime={eventItem?.startTime}
                  value={formState.regStartDate || null}
                  onChange={(date) => {
                    onDismissErrorHandler('regStartDate', date);
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'regStartDate', value: date } });
                  }}
                />
              </FormControl>
              <div>{Translation('common.to')}</div>
              <FormControl
                disabled={viewMode || disableEdit}
                className={commonFormClasses.dateTimePickerField}
                error={errorState.mandatory.regEndDate || errorState.immediate.endDateBeforeStartDate}
              >
                <PruDateTimePicker
                  disablePast
                  disabled={viewMode || disableEdit}
                  ampm={false}
                  label={
                    <>
                      {Translation('event.form.registration_end_time')}
                      <span className={commonFormClasses.mandatory}>*</span>
                    </>
                  }
                  slotProps={{
                    textField: {
                      size: 'medium',
                      variant: 'outlined',
                      error: errorState.mandatory.regEndDate || errorState.immediate.endDateBeforeStartDate,
                      helperText: errorState.immediate.endDateBeforeStartDate
                        ? Translation('event.form.registration_time_error')
                        : errorState.mandatory.regEndDate && MANDATORY_FIELD_ERROR_TEXT,
                    },
                  }}
                  format="DD/MM/YYYY HH:mm"
                  maxDateTime={eventItem?.startTime}
                  value={formState.regEndDate || null}
                  onChange={(date) => {
                    onDismissErrorHandler('regEndDate', date);
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'regEndDate', value: date } });
                  }}
                />
              </FormControl>
            </div>
            <div>
              <FormLabel className={commonFormClasses.formLabelNoSpacing}>
                {Translation('event.form.registration_confirmation')}
              </FormLabel>
            </div>
            <div>
              <FormControl
                fullWidth
                disabled={viewMode || isPublished || disableEdit}
                error={errorState.mandatory.regNotificationMethod}
              >
                <FormLabel className={commonFormClasses.formLabelNoSpacing}>
                  {Translation('event.form.send_via')}
                  <span className={commonFormClasses.mandatory}>*</span>
                </FormLabel>
                <div className={`${commonFormClasses.remark} ${classes.remarkMargin}`}>
                  {Translation('event.form.send_via.remark')}
                </div>
                <RadioGroup
                  className={commonFormClasses.radioGroup}
                  value={formState.regNotificationMethod || ''}
                  onChange={(e) => {
                    const dismissFields = ['regNotificationMethod'];
                    const newFormState: Partial<RegistrationAttendanceFormState> = {
                      regNotificationMethod: e.target.value as RegistrationNotificationMethodEnum,
                    };
                    if (e.target.value === RegistrationNotificationMethodEnum.SMS) {
                      regionLocale.forEach((locale) => {
                        dismissFields.push(`regConfirmationBody-title-${locale}`);
                      });
                      newFormState.regConfirmationBody = {
                        title: initI18nStringData,
                        body: formState.regConfirmationBody?.body || initI18nStringData,
                      };
                    }
                    onDismissErrorHandler(dismissFields, true);
                    formDispatch({
                      type: 'SET_FORM_STATE',
                      payload: { value: newFormState },
                    });
                  }}
                >
                  {map(RegistrationNotificationMethodEnum, (option) => (
                    <FormControlLabel
                      key={`send-via-${option}`}
                      className={commonFormClasses.formControlLabel}
                      control={<Radio />}
                      label={Translation(`event.form.send_via.${option.toLowerCase()}`)}
                      value={option}
                    />
                  ))}
                </RadioGroup>
                {errorState.mandatory.regNotificationMethod && (
                  <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                )}
              </FormControl>
            </div>
          </div>
        </Paper>
        <div>
          <MultiLangAccordion
            disabled={viewMode || disableEdit}
            error={(locale) =>
              errorState.mandatory[`regConfirmationBody-title-${locale}`] ||
              errorState.mandatory[`regConfirmationBody-body-${locale}`]
            }
            fillInOnChange={(locale) => {
              onDismissErrorHandler(
                [`regConfirmationBody-title-${locale}`, `regConfirmationBody-body-${locale}`],
                true,
              );
              formDispatch({
                type: 'FILL_IN_DETAIL',
                payload: { fromLocale: regionLocale[0], toLocale: locale },
              });
            }}
            renderChildren={(locale) => (
              <div className={commonFormClasses.smallGapContentContainer}>
                {formState.regNotificationMethod === RegistrationNotificationMethodEnum.EMAIL && (
                  <div>
                    <TextField
                      disabled={viewMode || disableEdit}
                      fullWidth
                      size="medium"
                      variant="outlined"
                      label={
                        <>
                          {Translation('event.common.title')}
                          <span className={commonFormClasses.mandatory}>*</span>
                        </>
                      }
                      placeholder={Translation('event.form.registration_confirmation.title.placeholder')}
                      error={errorState.mandatory[`regConfirmationBody-title-${locale}`]}
                      helperText={
                        errorState.mandatory[`regConfirmationBody-title-${locale}`] && MANDATORY_FIELD_ERROR_TEXT
                      }
                      value={formState.regConfirmationBody?.title[locale] || ''}
                      onChange={(e) => {
                        onDismissErrorHandler(`regConfirmationBody-title-${locale}`, e.target.value);
                        formDispatch({
                          type: 'MODIFY_REG_CONFIRMATION_BODY',
                          payload: { field: 'title', value: { [locale]: e.target.value } },
                        });
                      }}
                    />
                  </div>
                )}
                <div>
                  <FormLabel
                    disabled={viewMode || disableEdit}
                    className={commonFormClasses.formLabel}
                    error={errorState.mandatory[`regConfirmationBody-body-${locale}`]}
                  >
                    {Translation(
                      formState.regNotificationMethod === RegistrationNotificationMethodEnum.EMAIL
                        ? 'event.common.body'
                        : 'event.common.confirmation_body',
                    )}
                    <span className={commonFormClasses.mandatory}>*</span>
                  </FormLabel>
                  <Tinymce
                    disabled={viewMode || disableEdit}
                    module="event"
                    accessLevel="anonymous"
                    value={formState.regConfirmationBody?.body[locale] || ''}
                    onChange={(value) => {
                      formDispatch({
                        type: 'MODIFY_REG_CONFIRMATION_BODY',
                        payload: { field: 'body', value: { [locale]: value } },
                      });
                    }}
                  />
                  {errorState.mandatory[`regConfirmationBody-body-${locale}`] && (
                    <FormHelperText error={errorState.mandatory[`regConfirmationBody-body-${locale}`]}>
                      {MANDATORY_FIELD_ERROR_TEXT}
                    </FormHelperText>
                  )}
                </div>
              </div>
            )}
          />
        </div>
        <Paper className={commonFormClasses.paper}>
          <div className={commonFormClasses.titleContainer}>{Translation('event.form.attendance')}</div>
          <div className={commonFormClasses.smallGapContentContainer}>
            {eventItem?.multiSession ? (
              <div>
                <div className={commonFormClasses.titleContainer}>
                  {Translation('event.form.session_attendance_rule')}
                  <span className={commonFormClasses.mandatory}>*</span>
                </div>
                <PruTable
                  disableBulkSelect
                  disablePagination
                  hideListTitleRow
                  renderChildren
                  containerClassName={commonFormClasses.sessionTableContainer}
                  childRowClassName={commonFormClasses.subSessionRow}
                  headerBtnDef={[]}
                  operationDef={[]}
                  columnDef={[
                    {
                      keyIndex: 'session',
                      displayName: Translation('event.list.detail.sessions'),
                      renderData: (_, index, parent) =>
                        parent
                          ? `${Translation('event.form.sub_session')} ${index + 1}`
                          : `${Translation('event.form.session')} ${index + 1}`,
                    },
                    {
                      keyIndex: 'name',
                      displayName: Translation('event.list.detail.session_name'),
                      renderData: (row) => row.name[locale] || '--',
                    },
                    {
                      keyIndex: 'session_time',
                      displayName: Translation('event.form.session.time'),
                      renderData: (row) =>
                        `${moment(row.startTime).format('HH:mm')} - ${moment(row.endTime).format('HH:mm')}` || '--',
                    },
                    {
                      keyIndex: 'quota',
                      displayName: Translation('event.form.quota'),
                      renderData: (row) => row.quota || '--',
                    },
                    {
                      keyIndex: 'regAttendanceRule',
                      displayName: (
                        <>
                          {Translation('event.form.attendance_rule')}
                          <span className={commonFormClasses.mandatory}>*</span>
                        </>
                      ),
                      renderData: (row, index, parent) =>
                        row.subSessions.length === 0 || parent ? (
                          <Select
                            disabled={viewMode || isPublished || disableEdit}
                            displayEmpty
                            className={classes.tableSelect}
                            variant="outlined"
                            value={row.regAttendanceRule || ''}
                            renderValue={(selected) =>
                              selected ? (
                                Translation(`event.form.attendance_rule.${selected.toLowerCase()}`)
                              ) : (
                                <span className={commonFormClasses.placeholder}>
                                  {Translation('app.input.placeholder.please-select')}
                                </span>
                              )
                            }
                            onChange={(e) => {
                              if (parent) {
                                formDispatch({
                                  type: 'MODIFY_SUB_SESSION',
                                  payload: {
                                    parentIndex: parseInt(parent.key.replace('session', '')) - 1,
                                    index,
                                    value: {
                                      ...row,
                                      regAttendanceRule: e.target.value as RegistrationAttendanceRuleEnum,
                                    },
                                  },
                                });
                              } else {
                                formDispatch({
                                  type: 'MODIFY_SESSION',
                                  payload: {
                                    index,
                                    value: {
                                      ...row,
                                      regAttendanceRule: e.target.value as RegistrationAttendanceRuleEnum,
                                    },
                                  },
                                });
                              }
                            }}
                          >
                            {map(RegistrationAttendanceRuleEnum, (option) => (
                              <MenuItem key={option} value={option}>
                                {Translation(`event.form.attendance_rule.${option.toLowerCase()}`)}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          '--'
                        ),
                    },
                    {
                      keyIndex: 'regAllowWalkIn',
                      displayName: (
                        <>
                          {Translation('event.form.walk_in_allowed')}
                          <span className={commonFormClasses.mandatory}>*</span>
                        </>
                      ),
                      renderData: (row, index, parent) =>
                        row.subSessions.length === 0 || parent ? (
                          <FormControl
                            disabled={viewMode || isPublished || disableEdit}
                            variant="outlined"
                            error={errorState.mandatory[`${row.key}-regAllowWalkIn`]}
                          >
                            <Select
                              displayEmpty
                              className={classes.tableSelect}
                              variant="outlined"
                              value={row.regAllowWalkIn !== undefined ? row.regAllowWalkIn.toString() : ''}
                              renderValue={(selected) =>
                                selected ? (
                                  Translation(`app.select.${selected === 'true' ? 'yes' : 'no'}`)
                                ) : (
                                  <span className={commonFormClasses.placeholder}>
                                    {Translation('app.input.placeholder.please-select')}
                                  </span>
                                )
                              }
                              onChange={(e) => {
                                onDismissErrorHandler(`${row.key}-regAllowWalkIn`, true);
                                if (parent) {
                                  formDispatch({
                                    type: 'MODIFY_SUB_SESSION',
                                    payload: {
                                      parentIndex: parseInt(parent.key.replace('session', '')) - 1,
                                      index,
                                      value: {
                                        ...row,
                                        regAllowWalkIn: e.target.value === 'true' ? true : false,
                                      },
                                    },
                                  });
                                } else {
                                  formDispatch({
                                    type: 'MODIFY_SESSION',
                                    payload: {
                                      index,
                                      value: {
                                        ...row,
                                        regAllowWalkIn: e.target.value === 'true' ? true : false,
                                      },
                                    },
                                  });
                                }
                              }}
                            >
                              {trueFalseOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                  {Translation(`app.select.${option === 'true' ? 'yes' : 'no'}`)}
                                </MenuItem>
                              ))}
                            </Select>
                            {errorState.mandatory[`${row.key}-regAllowWalkIn`] && (
                              <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                            )}
                          </FormControl>
                        ) : (
                          '--'
                        ),
                    },
                    {
                      isId: true,
                      hidden: true,
                      keyIndex: 'key',
                      childKeyIndex: 'subSessions',
                      displayName: '',
                      renderData: () => '',
                    },
                  ]}
                  isLoading={false}
                  dataSource={formState.sessions}
                  defaultOpenedRows={formState.sessions.map((session) => session.key)}
                />
              </div>
            ) : (
              <>
                <div>
                  <FormControl
                    fullWidth
                    disabled={viewMode || isPublished || disableEdit}
                    error={errorState.mandatory.regAttendanceRule}
                  >
                    <FormLabel className={commonFormClasses.formLabel}>
                      {Translation('event.form.attendance_rule')}
                      <span className={commonFormClasses.mandatory}>*</span>
                    </FormLabel>
                    <RadioGroup
                      className={commonFormClasses.radioGroup}
                      value={formState.regAttendanceRule || ''}
                      onChange={(e) => {
                        onDismissErrorHandler('regAttendanceRule', true);
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: {
                            field: 'regAttendanceRule',
                            value: e.target.value,
                          },
                        });
                      }}
                    >
                      {map(RegistrationAttendanceRuleEnum, (option) => (
                        <FormControlLabel
                          key={`attendance-rule-${option}`}
                          className={commonFormClasses.formControlLabel}
                          control={<Radio />}
                          label={Translation(`event.form.attendance_rule.${option.toLowerCase()}`)}
                          value={option}
                        />
                      ))}
                    </RadioGroup>
                    {errorState.mandatory.regAttendanceRule && (
                      <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div>
                  <FormControl
                    fullWidth
                    disabled={viewMode || isPublished || disableEdit}
                    error={errorState.mandatory.regAllowWalkIn}
                  >
                    <FormLabel className={commonFormClasses.formLabel}>
                      {Translation('event.form.walk_in_allowed')}
                      <span className={commonFormClasses.mandatory}>*</span>
                    </FormLabel>
                    <RadioGroup
                      className={commonFormClasses.radioGroup}
                      value={typeof formState.regAllowWalkIn === 'boolean' ? formState.regAllowWalkIn.toString() : ''}
                      onChange={(e) => {
                        onDismissErrorHandler('regAllowWalkIn', true);
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: {
                            field: 'regAllowWalkIn',
                            value: e.target.value === 'true' ? true : false,
                          },
                        });
                      }}
                    >
                      {trueFalseOptions.map((option) => (
                        <FormControlLabel
                          key={option}
                          className={commonFormClasses.formControlLabel}
                          control={<Radio />}
                          label={Translation(`app.select.${option === 'true' ? 'yes' : 'no'}`)}
                          value={option}
                        />
                      ))}
                    </RadioGroup>
                    {errorState.mandatory.regAllowWalkIn && (
                      <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                    )}
                  </FormControl>
                </div>
              </>
            )}
          </div>
        </Paper>
      </div>
    </>
  );
};

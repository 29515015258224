import { FC } from 'react';
import { useIntl } from 'react-intl';
import { map } from 'lodash';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import { regionLocale } from 'src/app/i18n';
import { MANDATORY_FIELD_ERROR_TEXT, TIME_ERROR_TEXT, NUMBER_NA_ONLY_ERROR_TEXT } from 'src/app/common/constants';
import { ErrorState, DismissErrorHandler } from 'src/app/common/utils';
import PruIcon from 'src/app/common/components/PruIcon';
import { PruTimePicker } from 'src/app/common/components/PruDatePicker';
import { OnlineMeetingLinkDisplayEnum } from 'src/app/modules/event-v2/types';
import { SessionFormState } from 'src/app/modules/event-v2/pages/event-list/form/reducers';
import { useCommonFormStyles, MultiLangSection } from 'src/app/modules/event-v2/pages/event-list/form/common';
import { useStyles } from './sub-session-form.style';
import { useSubSessionForm } from './sub-session-form.hook';

type SubSessionFormProps = {
  viewMode: boolean;
  isPublished: boolean;
  disableEdit: boolean;
  isVirtual: boolean;
  sessionNum: number;
  subSessionNum: number;
  subSessionItem: SessionFormState;
  errorState: ErrorState;
  onDismissErrorHandler: DismissErrorHandler;
  updateSessionForm: (newSubSessionItem: SessionFormState) => void;
  removeSubSession: () => void;
};

export const SubSessionForm: FC<SubSessionFormProps> = ({
  viewMode,
  isPublished,
  disableEdit,
  isVirtual,
  sessionNum,
  subSessionNum,
  subSessionItem,
  errorState,
  onDismissErrorHandler,
  updateSessionForm,
  removeSubSession,
}) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const { classes } = useStyles();
  const { classes: commonFormClasses } = useCommonFormStyles();
  const { formState, formDispatch } = useSubSessionForm({
    subSessionItem,
    updateSessionForm,
  });
  const errorNameSuffix = `-sub${subSessionNum}`;

  return (
    <div className={`${commonFormClasses.contentContainer} ${classes.subSessionContainer}`}>
      <div className={classes.subSessionDeleteContainer}>
        <IconButton
          disabled={viewMode || isPublished || disableEdit}
          className={classes.deleteIcon}
          onClick={() => removeSubSession()}
        >
          <PruIcon icon="delete" style={{ fontSize: 24, width: 36, height: 36 }} />
        </IconButton>
      </div>
      <div>
        <div className={commonFormClasses.titleContainer}>
          {Translation('event.form.session')} {sessionNum} - {Translation('event.form.sub_session')} {subSessionNum}
        </div>
        <MultiLangSection
          disabled={viewMode || disableEdit}
          fillInOnChange={(locale) => {
            onDismissErrorHandler([`name-${locale}${errorNameSuffix}`], true);
            formDispatch({
              type: 'FILL_IN_DETAIL',
              payload: { fromLocale: regionLocale[0], toLocale: locale },
            });
          }}
          renderChildren={(locale) => (
            <>
              <div>
                <TextField
                  disabled={viewMode || disableEdit}
                  fullWidth
                  size="medium"
                  variant="outlined"
                  label={
                    <>
                      {Translation('event.form.session.sub_name', { num: subSessionNum.toString() })}
                      <span className={commonFormClasses.mandatory}>*</span>
                    </>
                  }
                  placeholder={Translation('event.form.session.name.placeholder')}
                  error={errorState.mandatory[`name-${locale}${errorNameSuffix}`]}
                  helperText={errorState.mandatory[`name-${locale}${errorNameSuffix}`] && MANDATORY_FIELD_ERROR_TEXT}
                  value={formState.name[locale] || ''}
                  onChange={(e) => {
                    onDismissErrorHandler(`name-${locale}${errorNameSuffix}`, e.target.value);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'name', value: { ...formState.name, [locale]: e.target.value } },
                    });
                  }}
                />
              </div>
              <div>
                <TextField
                  disabled={viewMode || disableEdit}
                  fullWidth
                  size="medium"
                  variant="outlined"
                  label={Translation('event.list.detail.venue')}
                  placeholder={Translation('event.form.session.venue.placeholder')}
                  value={formState.venue[locale] || ''}
                  onChange={(e) => {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'venue', value: { ...formState.venue, [locale]: e.target.value } },
                    });
                  }}
                />
              </div>
            </>
          )}
        />
      </div>
      {isVirtual && (
        <div>
          <FormLabel
            disabled={viewMode || disableEdit}
            className={commonFormClasses.formLabel}
            error={errorState.immediate[`onlineMeetingLinkFormat${errorNameSuffix}`]}
          >
            {Translation('event.form.online_meeting_link')}
          </FormLabel>
          <div className={commonFormClasses.smallGapContentContainer}>
            <div>
              <TextField
                disabled={viewMode || disableEdit}
                fullWidth
                size="medium"
                variant="outlined"
                label={Translation('event.form.online_meeting_link')}
                placeholder={Translation('event.form.online_meeting_link.placeholder')}
                error={errorState.immediate[`onlineMeetingLinkFormat${errorNameSuffix}`]}
                helperText={
                  errorState.immediate[`onlineMeetingLinkFormat${errorNameSuffix}`] &&
                  Translation('component.form-link-text')
                }
                value={formState.onlineMeetingLink || ''}
                onChange={(e) => {
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'onlineMeetingLink', value: e.target.value },
                  });
                }}
              />
              <div className={commonFormClasses.remark}>{Translation('event.form.online_meeting_link.remark')}</div>
            </div>
            <FormControl fullWidth disabled={viewMode || disableEdit}>
              <FormLabel className={commonFormClasses.formLabelMargin}>
                {Translation('event.form.online_meeting_link_display')}
              </FormLabel>
              <FormGroup className={commonFormClasses.radioGroup}>
                {map(OnlineMeetingLinkDisplayEnum, (option) => (
                  <FormControlLabel
                    key={`onlineMeetingLinkDisplay-${option}`}
                    className={commonFormClasses.formControlLabel}
                    control={
                      <Checkbox
                        checked={!!formState.onlineMeetingLinkDisplay?.includes(option)}
                        onChange={(e) =>
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'onlineMeetingLinkDisplay',
                              value: e.target.checked
                                ? [...(formState.onlineMeetingLinkDisplay || []), option]
                                : formState.onlineMeetingLinkDisplay?.filter((item) => item !== option),
                            },
                          })
                        }
                      />
                    }
                    label={
                      <span className={commonFormClasses.formLabelContainer}>
                        {Translation(`event.form.online_meeting_link_display.${option.toLowerCase()}`)}
                        <Tooltip
                          arrow
                          placement="bottom"
                          title={Translation(`event.form.online_meeting_link_display.${option.toLowerCase()}.tooltip`)}
                        >
                          <IconButton size={'small'} className={commonFormClasses.infoIcon}>
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </span>
                    }
                    labelPlacement="end"
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
        </div>
      )}
      <div>
        <FormLabel
          disabled={viewMode || disableEdit}
          className={commonFormClasses.formLabel}
          error={
            errorState.mandatory[`startTime${errorNameSuffix}`] ||
            errorState.mandatory[`endTime${errorNameSuffix}`] ||
            errorState.immediate[`endTimeBeforeStartTime${errorNameSuffix}`]
          }
        >
          {Translation('event.form.session.time')}
        </FormLabel>
        <div className={commonFormClasses.doublePickerContainer}>
          <FormControl
            disabled={viewMode || disableEdit}
            className={commonFormClasses.dateTimePickerField}
            error={
              errorState.mandatory[`startTime${errorNameSuffix}`] ||
              errorState.immediate[`endTimeBeforeStartTime${errorNameSuffix}`]
            }
          >
            <PruTimePicker
              disabled={viewMode || disableEdit}
              ampm={false}
              label={
                <>
                  {Translation('event.form.session.sub_start_time', { num: subSessionNum.toString() })}
                  <span className={commonFormClasses.mandatory}>*</span>
                </>
              }
              slotProps={{
                textField: {
                  size: 'medium',
                  variant: 'outlined',
                  error:
                    errorState.mandatory[`startTime${errorNameSuffix}`] ||
                    errorState.immediate[`endTimeBeforeStartTime${errorNameSuffix}`],
                  helperText: errorState.mandatory[`startTime${errorNameSuffix}`] && MANDATORY_FIELD_ERROR_TEXT,
                },
              }}
              value={formState.startTime}
              onChange={(date) => {
                onDismissErrorHandler(`startTime${errorNameSuffix}`, date);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'startTime', value: date } });
              }}
            />
          </FormControl>
          <div>{Translation('common.to')}</div>
          <FormControl
            disabled={viewMode || disableEdit}
            className={commonFormClasses.dateTimePickerField}
            error={
              errorState.mandatory[`endTime${errorNameSuffix}`] ||
              errorState.immediate[`endTimeBeforeStartTime${errorNameSuffix}`]
            }
          >
            <PruTimePicker
              disabled={viewMode || disableEdit}
              ampm={false}
              label={
                <>
                  {Translation('event.form.session.sub_end_time', { num: subSessionNum.toString() })}
                  <span className={commonFormClasses.mandatory}>*</span>
                </>
              }
              slotProps={{
                textField: {
                  size: 'medium',
                  variant: 'outlined',
                  error:
                    errorState.mandatory[`endTime${errorNameSuffix}`] ||
                    errorState.immediate[`endTimeBeforeStartTime${errorNameSuffix}`],
                  helperText: errorState.immediate[`endTimeBeforeStartTime${errorNameSuffix}`]
                    ? TIME_ERROR_TEXT
                    : errorState.mandatory[`endTime${errorNameSuffix}`] && MANDATORY_FIELD_ERROR_TEXT,
                },
              }}
              value={formState.endTime}
              onChange={(date) => {
                onDismissErrorHandler(`endTime${errorNameSuffix}`, date);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'endTime', value: date } });
              }}
            />
          </FormControl>
        </div>
      </div>
      <FormControl
        fullWidth
        disabled={viewMode || isPublished || disableEdit}
        error={errorState.mandatory[`quota${errorNameSuffix}`] || errorState.immediate[`quotaFormat${errorNameSuffix}`]}
      >
        <FormLabel className={commonFormClasses.formLabel}>
          {Translation('event.form.session.quota')}
          <span className={commonFormClasses.mandatory}>*</span>
        </FormLabel>
        <TextField
          disabled={viewMode || isPublished || disableEdit}
          size="medium"
          variant="outlined"
          error={
            errorState.mandatory[`quota${errorNameSuffix}`] || errorState.immediate[`quotaFormat${errorNameSuffix}`]
          }
          helperText={
            errorState.immediate[`quotaFormat${errorNameSuffix}`]
              ? NUMBER_NA_ONLY_ERROR_TEXT
              : errorState.mandatory[`quota${errorNameSuffix}`] && MANDATORY_FIELD_ERROR_TEXT
          }
          value={formState.quota || ''}
          onChange={(e) => {
            onDismissErrorHandler(`quota${errorNameSuffix}`, e.target.value);
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: { field: 'quota', value: e.target.value },
            });
          }}
        />
        <div className={`${commonFormClasses.remark} ${commonFormClasses.remarkMargin}`}>
          {Translation('event.form.session.quota.placeholder')}
        </div>
      </FormControl>
    </div>
  );
};

import { FC } from 'react';
import { FormMode } from 'src/app/common/types';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import {
  eventDetailToWalkInFormSaveClickGAData,
  eventDetailToPublishSaveClickGAData,
} from 'src/app/common/ga/types/ga-event';
import { ComponentProps } from 'src/app/common/components/pru-stepped-form-agent';
import SurveyCreator from 'src/app/common/components/survey-v2/survey-creator';
import { EventFormCommonProps } from 'src/app/modules/event-v2/types';
import { TopButton } from '../../../common';
import { useRegistrationForm } from './registration-form.hook';
import { defaultRegFormQuestionNames } from './constants';
import './registration-form.style.css';

type EventRegistrationFormProps = ComponentProps<EventFormCommonProps>;

export const EventRegistrationForm: FC<EventRegistrationFormProps> = ({ formCommonProps, ...rest }) => {
  const { formMode, isPublished, disableEdit, walkInAllowed, eventItem } = formCommonProps;
  const { regFormRef, handleSave, onSubmit } = useRegistrationForm({
    formCommonProps,
    ...rest,
  });
  const viewMode = formMode === FormMode.CREATE || formMode === FormMode.VIEW;
  const disableOperation = viewMode || isPublished || disableEdit;

  return (
    <>
      {eventItem && (
        <>
          <TopButton
            formMode={formMode}
            eventItem={eventItem}
            onSave={() => {
              const gaClickData = walkInAllowed
                ? eventDetailToWalkInFormSaveClickGAData
                : eventDetailToPublishSaveClickGAData;
              takeUIClickEvent(gaClickData);
              handleSave();
            }}
          />
          <div id="reg-form">
            <SurveyCreator
              key={formMode}
              ref={regFormRef}
              module="event"
              data={{
                content: eventItem.regFormBody as any,
              }}
              disableAddQuestionButton={disableOperation}
              disableLogo
              disableFileTypeQuestion
              customOptions={{
                haveCommercialLicense: true,
                showJSONEditorTab: false,
                showTranslationTab: true,
                showThemeTab: true,
              }}
              customOnPageAdded={(options, pageCounter) => {
                const page = options.page;
                page.name = `page${pageCounter}`;
                page.title = '';
              }}
              customOnQuestionAdded={(options) => {
                options.question.locRequiredErrorText.setLocaleText('default', 'Field is required');
                options.question.locRequiredErrorText.setLocaleText('zh-tw', '欄位為必填項');
              }}
              customOnDragDropAllow={(options) => {
                options.allow = true;
              }}
              customOnElementAllowOperations={(options) => {
                options.allowCopy = false;
                if (
                  disableOperation ||
                  defaultRegFormQuestionNames.includes(options.obj.name) ||
                  options.obj.name?.startsWith('register_session')
                ) {
                  options.allowDelete = false;
                  options.allowDragging = false;
                  options.allowChangeType = false;
                  options.allowChangeInputType = false;
                  options.allowChangeRequired = false;
                }
              }}
              customOnGetPropertyReadOnly={(options) => {
                if (
                  disableOperation ||
                  defaultRegFormQuestionNames.includes(options.obj.name) ||
                  options.obj.name?.startsWith('register_session')
                ) {
                  options.readOnly = true;
                } else {
                  options.readOnly = false;
                }
              }}
              onChangeContent={onSubmit}
            />
          </div>
        </>
      )}
    </>
  );
};

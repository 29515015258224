import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Box, Button, CardContent, Dialog, Grid, Stack, Typography } from '@mui/material';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import {
  eventDetailEditAssistantClickGAData,
  eventDetailRemoveAssistantClickGAData,
} from 'src/app/common/ga/types/ga-event';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useEventAssistantList } from './event-assistant-list.hook';
import ProFilterTable, { ProColumns } from 'src/app/common/components/ProTable';
import { Add } from '@mui/icons-material';
import { EventAssistantDialog } from '../components/event-assistant-dialog/event-assistant-dialog.component';
import { ProTableOperationBtnComponent } from 'src/app/common/components/ProTable/components';
import { EventAssistantItem, EventFormCommonProps, EventPublishStatusEnum } from 'src/app/modules/event-v2/types';
import { useParams } from 'react-router-dom';
import { SendLinkDialog } from '../components/send-link-dialog/send-link-dialog.component';
import { Card } from 'src/app/common/components/card/card.component';
import { renderValue } from 'src/app/modules/event-v2/util/event-v2.util';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  formCommonProps: EventFormCommonProps;
};

export const EventAssistantListComponent: React.FC<ComponentProps> = memo(({ formCommonProps }: ComponentProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { eventItem } = formCommonProps;

  const enableAddAction = useMemo(() => {
    return (
      (eventItem?.publishStatus === EventPublishStatusEnum.DRAFT &&
        moment().isSameOrBefore(eventItem.eventDate, 'day')) ||
      (eventItem?.publishStatus === EventPublishStatusEnum.PUBLISHED && moment().isSameOrBefore(eventItem.startTime))
    );
  }, [eventItem?.eventDate, eventItem?.publishStatus, eventItem?.startTime]);

  const disableSendLinkAction = useMemo(
    () => !id || (eventItem?.eventDate ? moment().isAfter(eventItem.eventDate, 'day') : false),
    [eventItem, id],
  );

  const disableOperation = useMemo(() => {
    if (eventItem) {
      return (
        eventItem.publishStatus === EventPublishStatusEnum.UNPUBLISHED ||
        (eventItem.publishStatus === EventPublishStatusEnum.PUBLISHED && moment().isSameOrAfter(eventItem.eventDate))
      );
    }
    return false;
  }, [eventItem?.publishStatus, eventItem?.eventDate]);

  // custom-hook
  const {
    getData,
    actionRef,
    onRefresh,
    eventAssistantDialogState,
    onOpenEventAssistantDialog,
    onCloseEventAssistantDialog,
    onRemove,
    sendLinkDialogState,
    setSendLinkDialogState,
    resetState,
  } = useEventAssistantList({ eventId: id });

  const rowSelection = useMemo(
    () => ({
      onChange: (rows: any) => {
        setSendLinkDialogState((prev) => ({ ...prev, selectedRows: rows }));
      },
      getCheckboxProps: (row: any, rows: any[]) => {
        return {};
      },
    }),
    [setSendLinkDialogState],
  );

  const operationColum: ProColumns<EventAssistantItem>[] = useMemo(() => {
    return disableOperation
      ? []
      : [
          {
            title: t('section.common.operation'),
            sticky: true,
            dataIndex: 'operation',
            width: '176px',
            render: (currValue: string, item: EventAssistantItem) =>
              renderOperation({ item, onOpenEventAssistantDialog, onRemove }),
          },
        ];
  }, [onOpenEventAssistantDialog, onRemove, t, disableOperation]);

  return (
    <Card sx={{ m: 3, p: 1 }}>
      <CardContent>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography sx={{ mb: 1, fontSize: '18px', fontWeight: 600 }}>{t('event.form.event_assistant')}</Typography>
            <Typography color="text.secondary" sx={{ fontSize: '12px', fontWeight: 500 }}>
              {t('event.form.event_assistant.remark')}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              disabled={sendLinkDialogState.selectedRows?.length === 0}
              onClick={() => setSendLinkDialogState({ ...sendLinkDialogState, open: true })}
              sx={{ fontWeight: 500, fontSize: '14px' }}
            >
              {t('app.button.send_link')}
            </Button>
          </Grid>
        </Grid>

        {id && (
          <Stack sx={{ mt: 3 }}>
            <ProFilterTable
              showFilter={false}
              enableRefresh={false}
              showSelect={!disableSendLinkAction}
              rowKey="_id"
              columns={[
                {
                  title: t('event.form.assistant_name'),
                  dataIndex: 'name',
                  width: '290px',
                  render: renderValue,
                },
                {
                  title: t('event.form.email_address'),
                  dataIndex: 'email',
                  width: '290px',
                  render: renderValue,
                },

                {
                  title: t('event.common.created_by'),
                  dataIndex: 'createdBy',
                  width: '176px',
                  render: renderValue,
                },
                {
                  title: t('event.common.last_updated_by'),
                  dataIndex: 'lastUpdatedBy',
                  width: '176px',
                  render: renderValue,
                },
                ...operationColum,
              ]}
              request={getData}
              operationSticky
              actionRef={actionRef}
              rowSelection={rowSelection}
            />
          </Stack>
        )}

        <div
          className="tw-h-20 tw-p-5 tw-mt-6 tw-flex tw-flex-row tw-justify-center tw-items-center tw-rounded-lg tw-border-dashed tw-border tw-border-[#ccc] tw-cursor-pointer"
          onClick={() => {
            if (enableAddAction) return onOpenEventAssistantDialog();
            dispatch(
              appendAlertItem([{ severity: AlertType.WARNING, content: t('event.form.add_event_assistant_failed') }]),
            );
          }}
        >
          <Add sx={{ color: enableAddAction ? '#333333' : '#999999' }} />
          <Typography sx={{ fontSize: 16, fontWeight: 500, ml: 1, color: enableAddAction ? '#333333' : '#999999' }}>
            {t('event.form.add_event_assistant')}
          </Typography>
        </div>
      </CardContent>

      {eventAssistantDialogState.open && (
        <EventAssistantDialog
          dialogState={eventAssistantDialogState}
          onClose={onCloseEventAssistantDialog}
          refreshEventAssistantList={onRefresh}
        />
      )}

      {sendLinkDialogState.open && (
        <SendLinkDialog
          dialogState={sendLinkDialogState}
          onClose={() => setSendLinkDialogState({ ...sendLinkDialogState, open: false })}
          resetDialogState={resetState}
        />
      )}
    </Card>
  );
});

const renderOperation = ({
  item,
  onOpenEventAssistantDialog,
  onRemove,
}: {
  item: EventAssistantItem;
  onOpenEventAssistantDialog: (item?: EventAssistantItem) => void;
  onRemove: (param: string) => void;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <ProTableOperationBtnComponent
        label={t('app.button.edit')}
        onClick={() => {
          takeUIClickEvent(eventDetailEditAssistantClickGAData);
          onOpenEventAssistantDialog(item);
        }}
      />
      <ProTableOperationBtnComponent
        label={t('app.button.remove')}
        onClick={() => {
          takeUIClickEvent(eventDetailRemoveAssistantClickGAData);
          onRemove(item._id);
        }}
      />
    </>
  );
};

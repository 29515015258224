import { Dispatch } from 'react';
import { I18nGenericData } from 'src/app/i18n';
import { getQueryPath } from 'src/app/common/utils';
import { AttachmentDef, PaginateList, Agent } from 'src/app/common/types';
import { apiClient, apiErrorHandler, ApiErrorResponse, AGENT_URL, MY_AGENT_ENDPOINT } from 'src/app/common/network';
import {
  EventTypeEnum,
  OnlineMeetingLinkDisplayEnum,
  SessionEnrollmentEnum,
  EventPublishStatusEnum,
  EventImageTypeEnum,
  DescriptionTypeEnum,
  RegistrationMethodEnum,
  RegistrationNotificationMethodEnum,
  RegistrationAttendanceRuleEnum,
  PublishAgentEnum,
  AgentForceEnrolEnum,
  EventItem,
  EventListItem,
  EventTagItem,
  EventMaterialListItem,
  EventSessionItem,
  EventConfirmationBodyItem,
  EventAgentConfig,
  EventShortenCodeUrlItem,
} from '../types';
import { eventEndpoint } from './constants';

export type EventListParam = {
  search: string;
  categories: string[];
  accessibilities: string[];
  audience: string[];
  eventStatuses: string[];
  publishStatuses?: string;
  page: number;
  limit: number;
  sort?: string;
};

export type EventTagListParam = {
  locale: string;
  pagination: boolean;
};

export type DownlineAgentListParam = {
  search: string;
  page: number;
  limit: number;
  sort?: string;
};

export type EligibleAgentListParam = {
  agentCodes: string[];
};

export type CreateUpdateEventBody = {
  /* Basics starts */
  category?: string;
  shareToPublic?: boolean | null;
  agentRequired?: boolean | null;
  type?: EventTypeEnum;
  eventDate?: Date | null;
  startTime?: Date | null;
  endTime?: Date | null;
  organizer?: I18nGenericData<string>;
  name?: I18nGenericData<string>;
  venue?: I18nGenericData<string>;
  tags?: I18nGenericData<string[]>;
  onlineMeetingLink?: string;
  onlineMeetingLinkDisplay?: OnlineMeetingLinkDisplayEnum[];
  multiSession?: boolean;
  sessions?: EventSessionItem[];
  sessionEnrollment?: SessionEnrollmentEnum;
  quota?: string;
  publishStatus?: EventPublishStatusEnum;
  /* Basics ends */

  /* Details starts */
  eventImageType?: I18nGenericData<EventImageTypeEnum>;
  eventImage?: I18nGenericData<AttachmentDef>;
  selectedEventImage?: I18nGenericData<string>;
  descriptionType?: I18nGenericData<DescriptionTypeEnum>;
  descriptionImage?: I18nGenericData<AttachmentDef>;
  description?: I18nGenericData<string>;
  attachments?: I18nGenericData<AttachmentDef[]>;
  /* Details ends */

  /* Registration & Attendance starts */
  regQuotaPerAgent?: number | null;
  regStartDate?: Date | null;
  regEndDate?: Date | null;
  regMethod?: RegistrationMethodEnum;
  regLink?: string;
  regNotificationMethod?: RegistrationNotificationMethodEnum;
  regConfirmationBody?: EventConfirmationBodyItem;
  regAttendanceRule?: RegistrationAttendanceRuleEnum;
  regAllowWalkIn?: boolean | null;
  /* Registration & Attendance ends */

  /* Registration Form starts */
  regFormBody?: Record<string, any>;
  /* Registration Form ends */

  /* Walk in Form starts */
  walkInFormBody?: Record<string, any>;
  /* Walk in Form ends */

  /* Publish starts */
  pubAgent?: PublishAgentEnum;
  pubAgentConfig?: EventAgentConfig;
  agentForceEnrol?: AgentForceEnrolEnum;
  agentForceEnrolConfig?: EventAgentConfig;
  /* Publish ends */
};

type ValidateAgentListBody = {
  blobId: string;
};

export const fetchEventList = async (
  params: EventListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<EventListItem>> => {
  const queryPath = getQueryPath(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/${eventEndpoint}/agency-led-events?`, params);
  return apiClient
    .get<PaginateList<EventListItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchEventTagList = async (
  params: EventTagListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<EventTagItem>> => {
  const queryPath = getQueryPath(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/${eventEndpoint}/tags?`, params);
  return apiClient
    .get<PaginateList<EventTagItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchEventMaterialList = async (id: string, dispatch?: Dispatch<any>): Promise<EventMaterialListItem> => {
  return apiClient
    .get<EventMaterialListItem>(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/${eventEndpoint}/${id}/materials`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchDownlineAgentList = async (
  params: DownlineAgentListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<Agent>> => {
  const queryPath = getQueryPath(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/${eventEndpoint}/downline/list?`, params);
  return apiClient
    .get<PaginateList<Agent>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchEligibleAgentList = async (
  params: EligibleAgentListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<Agent>> => {
  const queryPath = getQueryPath(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/${eventEndpoint}/eligible-agents/list?`, params);
  return apiClient
    .get<PaginateList<Agent>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchEventItem = async (id: string, dispatch?: Dispatch<any>): Promise<EventItem> => {
  return apiClient
    .get<EventItem>(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/${eventEndpoint}/agency-led-events/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchEventShortenCodeUrl = async (
  id: string,
  dispatch?: Dispatch<any>,
): Promise<EventShortenCodeUrlItem> => {
  return apiClient
    .get<EventShortenCodeUrlItem>(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/${eventEndpoint}/${id}/shorten-code-url`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const validateEventBeforePublish = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .get<void>(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/${eventEndpoint}/${id}/validate-publish`)
    .then((response) => response.data)
    .catch((err) => {
      const error = err as ApiErrorResponse;
      throw apiErrorHandler({ ...error, message: error.errors[0].message }, dispatch);
    });
};

export const downloadAgentListTemplate = async (dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/${eventEndpoint}/agent-list/template`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createEventItem = async (body: CreateUpdateEventBody, dispatch?: Dispatch<any>): Promise<EventItem> => {
  return apiClient
    .post<EventItem>(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/${eventEndpoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const validateAgentList = async (body: ValidateAgentListBody): Promise<any> => {
  return apiClient
    .post<any>(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/${eventEndpoint}/agent-list/validate`, body)
    .then((response) => response.data)
    .catch();
};

export const modifyEventItem = async (
  id: string,
  body: CreateUpdateEventBody,
  dispatch?: Dispatch<any>,
): Promise<EventItem> => {
  return apiClient
    .patch<EventItem>(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/${eventEndpoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      const error = err as ApiErrorResponse;
      throw apiErrorHandler({ ...error, message: error.errors[0].message }, dispatch);
    });
};

export const deleteEventItem = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/${eventEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

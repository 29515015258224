import { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button, CircularProgress } from '@mui/material';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { TakeUIClickEventParameters } from 'src/app/common/ga/types/ga-general-type';
import { PruToast } from 'src/app/common/components/pru-toast';
import {
  StepStatusEnum,
  FormCommonProps,
  UpdateStepStatusProps,
  OnStepChangeProps,
  ComponentUpdateStepStatusProps,
  StepItem,
  StepIndicator,
} from './types/pru-stepped-form-types';
import { useStyles } from './pru-stepped-form.style';
import { PruStepper } from './components/pru-stepper';

// PruSteppedForm (Agent Portal Version)
type PruSteppedFormProps<T extends FormCommonProps> = {
  initialActiveStep: StepIndicator;
  initialSteps: StepItem<T>[];
  formCommonProps: T;
  onStepperClickGAData?: TakeUIClickEventParameters;
};

const initSteps = <T extends FormCommonProps>(sourceSteps: StepItem<T>[], activeStep: StepIndicator): StepItem<T>[] =>
  sourceSteps.map((sourceStepItem, index) => {
    const isActive = index === activeStep?.stepIndex;
    return {
      ...sourceStepItem,
      status: sourceStepItem.status || (isActive ? StepStatusEnum.PROGRESS : StepStatusEnum.DRAFT),
    };
  });

export const PruSteppedForm = <T extends FormCommonProps>({
  initialActiveStep,
  initialSteps,
  formCommonProps,
  onStepperClickGAData,
}: PruSteppedFormProps<T>) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes, cx } = useStyles();

  const [activeStep, setActiveStep] = useState<StepIndicator>(initialActiveStep);
  const [steps, setSteps] = useState<StepItem<T>[]>(initSteps(initialSteps, initialActiveStep));
  const [disableNavigation, setDisableNavigation] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const timeoutIdRef = useRef<any>();
  const discardChangesRef = useRef<() => void>();

  useEffect(() => {
    // disable navigation when changesUnsaved is true
    // enable navigation thru onStepChange & componentUpdateStepStatus
    if (formCommonProps.changesUnsaved) {
      setDisableNavigation(true);
    }
  }, [formCommonProps.changesUnsaved]);

  useEffect(() => {
    if (showAlert) {
      timeoutIdRef.current = setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [showAlert]);

  const updateStepStatus = ({
    newStatus,
    stepIndicator = activeStep,
    stepItems = steps,
    forceUpdateStatus,
  }: UpdateStepStatusProps<T>): StepItem<T>[] => {
    const newStepItems = [...stepItems];
    const stepItem = newStepItems[stepIndicator.stepIndex];
    if (
      stepItem.status &&
      (forceUpdateStatus ||
        (stepItem.status !== StepStatusEnum.FINISHED && stepItem.status !== StepStatusEnum.WARNING) ||
        newStatus === StepStatusEnum.FINISHED ||
        newStatus === StepStatusEnum.WARNING)
    ) {
      stepItem.status = newStatus;
    }
    return newStepItems;
  };

  const onStepChange = ({
    newActiveStep,
    currentStepStatus = StepStatusEnum.DRAFT,
    forceNavigation,
    saveChanges,
  }: OnStepChangeProps) => {
    if (!disableNavigation || forceNavigation) {
      if (forceNavigation) {
        setDisableNavigation(false);
      }
      // update current step's status
      const newSteps = updateStepStatus({ newStatus: currentStepStatus });
      // update new step's status
      setSteps(
        updateStepStatus({ newStatus: StepStatusEnum.PROGRESS, stepIndicator: newActiveStep, stepItems: newSteps }),
      );
      setActiveStep(newActiveStep);
    }
    if (saveChanges) {
      formCommonProps.setChangesUnsaved(false);
    } else if (formCommonProps.changesUnsaved) {
      showUnsavedChangesAlert();
    }
  };

  const componentUpdateStepStatus = ({
    newStatus,
    forceNavigation,
    forceUpdateStatus,
    saveChanges,
    stepIndicatorList,
  }: ComponentUpdateStepStatusProps) => {
    if (newStatus === StepStatusEnum.WARNING && !forceNavigation) {
      setDisableNavigation(true);
    } else {
      setDisableNavigation(false);
      if (saveChanges) {
        formCommonProps.setChangesUnsaved(false);
      }
    }
    let newSteps = updateStepStatus({ newStatus, forceUpdateStatus });
    if (stepIndicatorList) {
      stepIndicatorList.forEach((stepIndicator) => {
        newSteps = updateStepStatus({ newStatus, stepIndicator, stepItems: newSteps, forceUpdateStatus });
      });
    }
    setSteps(newSteps);
  };

  const showToast = (message: string) => {
    PruToast({ message });
  };

  const showUnsavedChangesAlert = () => {
    if (!showAlert) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            content: Translation('common.toast.unsaved_changes'),
            action: (onClose) => (
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  if (discardChangesRef.current) {
                    discardChangesRef.current();
                  }
                  if (timeoutIdRef.current) {
                    clearTimeout(timeoutIdRef.current);
                  }
                  onClose();
                  setShowAlert(false);
                }}
              >
                {Translation('common.toast.discard_change')}
              </Button>
            ),
          },
        ]),
      );
      setShowAlert(true);
    }
  };

  const renderStepComponent = (activeStep: StepIndicator, stepItems: StepItem<T>[]): JSX.Element => {
    const stepItem = stepItems[activeStep.stepIndex];
    const isLoading = formCommonProps.isLoading;
    return (
      <>
        {isLoading && (
          <div className={classes.loadingContainer}>
            <CircularProgress color="secondary" />
          </div>
        )}
        {stepItem.title && (
          <div className={cx(classes.title, isLoading ? classes.hidden : undefined)}>{stepItem.title}</div>
        )}
        {stepItem.component && (
          <div className={cx(classes.component, stepItem.componentClassName, isLoading ? classes.hidden : undefined)}>
            {stepItem.component({
              formCommonProps,
              onStepChange,
              componentUpdateStepStatus,
              showToast,
              setDiscardChanges: (discardChanges) => {
                discardChangesRef.current = discardChanges;
              },
            })}
          </div>
        )}
      </>
    );
  };

  return (
    <div className={classes.container}>
      <PruStepper
        steps={steps}
        activeStep={activeStep}
        formCommonProps={formCommonProps}
        onStepperClickGAData={onStepperClickGAData}
        onStepChange={(newActiveStep) => onStepChange({ newActiveStep })}
      />
      <div className={classes.contentContainer}>{renderStepComponent(activeStep, steps)}</div>
    </div>
  );
};

import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Button, Dialog, DialogTitle, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Agent } from 'src/app/common/types';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import { eventDetailPublishSelectAgentClickGAData } from 'src/app/common/ga/types/ga-event';
import ProFilterTable from 'src/app/common/components/ProTable';
import { ProSearchFilter } from 'src/app/common/components/ProTable/components';
import { useAddAgentFromListDialog } from './add-agent-from-list-dialog.hook';
import { useStyles } from './add-agent-from-list-dialog.style';

type AddAgentFromListDialogProps = {
  defaultSelected: Agent[];
  open: boolean;
  onClose: () => void;
  onAddAgent: (agentCodeList: string[]) => void;
};

export const AddAgentFromListDialog: FC<AddAgentFromListDialogProps> = ({
  defaultSelected,
  open,
  onClose,
  onAddAgent,
}) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const { classes } = useStyles();
  const { selectedAgents, columns, setSelectedAgents, getAgentList, onFilterChange } = useAddAgentFromListDialog();

  return (
    <Dialog fullScreen open={open}>
      <DialogTitle className={classes.headerContainer}>
        <div className={classes.titleContainer}>
          <IconButton onClick={onClose}>
            <Close className={classes.closeIcon} />
          </IconButton>
          <span className={classes.title}>{Translation('common.add_from_list')}</span>
        </div>
        <div className={classes.buttonContainer}>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            {Translation('app.button.cancel')}
          </Button>
          <Button
            disabled={selectedAgents.length === 0}
            variant="contained"
            color="secondary"
            onClick={() => {
              takeUIClickEvent(eventDetailPublishSelectAgentClickGAData);
              onAddAgent(selectedAgents);
              onClose();
            }}
          >
            {Translation('app.button.import')}
          </Button>
        </div>
      </DialogTitle>
      <div className={classes.contentContainer}>
        <ProFilterTable
          showFilterIcon={false}
          enableRefresh={false}
          rowKey="agentCode"
          Filter={ProSearchFilter}
          filterState={{ filterState: { pageSize: 20 } }}
          filterPlaceholder={Translation('common.search.placeholder.unit_agent_designation_code')}
          columns={columns}
          request={getAgentList}
          onFilterChange={onFilterChange}
          operationSticky
          rowSelection={{
            onChange: (rows) => {
              setSelectedAgents(rows.map((row) => row.agentCode));
            },
            getCheckboxProps: (row) => {
              return {
                disabled: !!defaultSelected.find((selected) => selected.agentCode === row.agentCode),
              };
            },
          }}
        />
      </div>
    </Dialog>
  );
};

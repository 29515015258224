import { makeStyles } from 'tss-react/mui';

export const FIELD_COLUMN_GAP = 24;
const FIELD_ROW_GAP = 40;
const FIELD_ROW_GAP_SMALL = 24;
const LABEL_FIELD_SPACING = 14;
const DATE_TIME_FIELD_WIDTH = 320;

export const useCommonFormStyles = makeStyles()(() => ({
  topButtonContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    columnGap: 20,
    top: 0,
    right: 0,
  },
  paperContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 24,
    paddingBottom: 8,
  },
  paper: {
    padding: '24px 24px 32px',
  },
  titleContainer: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 24,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: FIELD_ROW_GAP,
  },
  smallGapContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: FIELD_ROW_GAP_SMALL,
  },
  singleFieldPadding: {
    paddingRight: `calc(50% + ${FIELD_COLUMN_GAP / 2}px)`,
  },
  dateTimePickerField: {
    width: DATE_TIME_FIELD_WIDTH,
  },
  doublePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 16,
  },
  radioGroup: {
    marginLeft: 10,
    flexDirection: 'row',
    columnGap: 36,
  },
  formLabelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  formLabel: {
    display: 'block',
    color: '#3F4254',
    fontSize: 14,
    fontWeight: 600,
    marginBottom: LABEL_FIELD_SPACING,
  },
  formLabelMargin: {
    marginBottom: 8,
  },
  formLabelNoSpacing: {
    display: 'block',
    color: '#3F4254',
    fontSize: 14,
    fontWeight: 600,
  },
  formControlLabel: {
    flex: 1,
    padding: '8px 6px',
    border: '1px solid #F0F0F0',
    borderRadius: 8,
    marginRight: 0,
    marginBottom: 0,
  },
  sessionTableContainer: {
    boxShadow: '0 8px 16px -4px rgba(153, 153, 153, 0.16)',
  },
  subSessionRow: {
    backgroundColor: '#FAFAFA',
  },
  mandatory: {
    color: 'red',
  },
  placeholder: {
    color: '#858585',
  },
  remark: {
    color: '#979797',
    fontSize: 12,
    fontWeight: 400,
  },
  remarkMargin: {
    marginLeft: 16,
  },
  infoIcon: {
    padding: 5,
    color: '#CCCCCC',
  },
}));

import { FC, useEffect, useState, useCallback } from 'react';
import { Dialog, Button, CircularProgress, Modal } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { map, debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../redux/store';
import { AuthApiRes } from '../../modules/Auth/types/auth-types';
import { logout, setExpired } from '../../modules/Auth/network/authSlice';
import { authenticateFailed } from '../../../redux/common/commonSlice';
import { refreshToken } from '../../modules/Auth/network/authCrud';
import { useUpdateEffect } from '../utils/hook-utils';
import { SurveyPermissionType } from '../../modules/Survey/constants';
import { DownloadPagePermissionType } from '../../modules/Application/constants';
import ifvisible from 'ifvisible';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('xs')]: {
      padding: '25px 40px',
      width: '100vw',
    },
    [theme.breakpoints.up('sm')]: {
      padding: 20,
      width: 'auto',
      height: 'auto',
    },
  },
  header: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  noMargin: {
    margin: 0,
  },
}));

type HandlerInternal = {
  hasToken: boolean;
  isExpired: boolean;
  isTempToken: boolean;
};

const tempTokenDef = [DownloadPagePermissionType.TEMP_READ, SurveyPermissionType.TEMP_CREATE];

// Define input selectors
const getTokenInfo = (state: RootState) => state.auth.user;
const getIsExpired = (state: RootState) => state.auth?.isExpired;

// Define the result function
const tokenHandlerSelector = createSelector([getTokenInfo, getIsExpired], (tokenInfo, isExpired) => {
  let isTempToken = false;
  if (tokenInfo) {
    const permissionKeyArr = map(tokenInfo.permissions, (_, key) => key);
    if (permissionKeyArr.length === 1 && tempTokenDef.find((tempDef) => tempDef === permissionKeyArr[0])) {
      isTempToken = true;
    }
  }
  return {
    hasToken: !!tokenInfo,
    isExpired: !!isExpired,
    isTempToken,
  };
});

const TokenHandler: FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const currentPath = useHistory().location.pathname;
  const { hasToken, isExpired, isTempToken } = useSelector(tokenHandlerSelector);

  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [promptMessageOpen, setPromptMessageOpen] = useState<boolean>(false);

  const onLogout = async () => {
    dispatch(logout());
    window.location.reload();
  };

  const autoLogout = async () => {
    dispatch(authenticateFailed());
    onLogout();
  };

  const refreshTokenHandle = (res: AuthApiRes) => {
    window.localStorage.setItem('jwt', res.accessToken);
    window.localStorage.setItem('refreshToken', res.refreshToken);
    const expireDate = moment(new Date()).add(res.expiresIn, 's').toDate();
    const abondonSession = moment(new Date()).add(1, 'd').toDate();
    window.localStorage.setItem('expireDate', expireDate.toISOString());
    window.localStorage.setItem('abondonSession', abondonSession.toISOString());
  };

  //  Auto refresh token when route change and token expire in 1 min ( REFRESH_TIME_DIFF_IN_MIN ).
  //  eslint-disable-next-line
  const debounceRefresh = useCallback(
    debounce(() => {
      const REFRESH_TIME_DIFF_IN_MIN = 1;

      const token = window.localStorage.getItem('refreshToken');
      const expiredDate = window.localStorage.getItem('expireDate');
      const willExpireSoon =
        !expiredDate || moment(new Date()).isAfter(moment(expiredDate).subtract(REFRESH_TIME_DIFF_IN_MIN, 'm'));

      if (!isExpired && willExpireSoon) {
        if (token) {
          refreshToken(token, dispatch)
            .then(refreshTokenHandle)
            .catch((err) => {
              console.error('Error on auto-refresh-token: ', err);
            });
        } else {
          autoLogout();
        }
      }
    }, 1500),
    [],
  );

  useUpdateEffect(() => {
    if (hasToken && !isTempToken) {
      debounceRefresh();
    }
    // eslint-disable-next-line
  }, [currentPath]);

  //  Click button on dialog to refresh token
  const onRefreshToken = () => {
    const token = window.localStorage.getItem('refreshToken');
    if (token) {
      setIsRefreshing(true);
      refreshToken(token, dispatch)
        .then(refreshTokenHandle)
        .catch(() => {
          autoLogout();
        })
        .finally(() => {
          dispatch(setExpired(false));
          setIsRefreshing(false);
          // no need to reload page here
          // window.location.reload();
        });
    } else {
      autoLogout();
    }
  };

  useUpdateEffect(() => {
    const delayRefresh = async () => {
      if (isExpired && isTempToken) {
        setPromptMessageOpen(true);
        dispatch(logout());
      }
    };
    delayRefresh();
  }, [isExpired, isTempToken]);
  /*
  to improve performance use ifvisible instead of setInterval
  useEffect(() => {
    if (hasToken) {
      setInterval(() => {
        const expireDate = window.localStorage.getItem('expireDate');
        const abondonSession = window.localStorage.getItem('abondonSession');
        const abondonFlag = abondonSession && moment(new Date()).isAfter(moment(abondonSession));
        const openDialogFlag =
          !isExpired &&
          expireDate &&
          moment(new Date()).isAfter(moment(expireDate)) &&
          moment(new Date()).isBefore(moment(abondonSession));
        if (abondonFlag) {
          dispatch(logout());
        }
        if (openDialogFlag) {
          dispatch(setExpired(true));
        }
      }, 1000);
    }
    // eslint-disable-next-line
  }, []);
  */
  const checkSessionExpiration = () => {
    const expiredDate = window.localStorage.getItem('expireDate');

    // check if need to open "refresh session" dialog
    const abondonSession = window.localStorage.getItem('abondonSession');
    const abondonFlag = abondonSession && moment(new Date()).isAfter(moment(abondonSession));

    const openDialogFlag =
      !isExpired &&
      expiredDate &&
      moment(new Date()).isAfter(moment(expiredDate)) &&
      moment(new Date()).isBefore(moment(abondonSession));
    if (abondonFlag) {
      dispatch(logout());
      return;
    }
    // token is expired, but not abondon yet, open dialog to ask user manually refresh token
    if (openDialogFlag) {
      dispatch(setExpired(true));
      return;
    }
  };

  useEffect(() => {
    if (!hasToken) {
      return;
    }
    /**
     * check session once when page mounts, then if page is visible, check session every x seconds.
     */
    checkSessionExpiration();
    // This method is smart and it will stop executing when the page is not active
    ifvisible.onEvery(30, checkSessionExpiration);

    // Clean up the event listener on component unmount
    return () => {
      ifvisible.off('every'); // Clear the interval on unmount
    };
  }, []);

  return (
    <Modal open={promptMessageOpen || (hasToken && isExpired)}>
      <Dialog
        open={true}
        PaperProps={{
          classes: {
            root: classes.noMargin,
          },
        }}
      >
        <div className={classes.root}>
          {promptMessageOpen ? (
            <>
              <div className={classes.header}>Your temporary session has expired.</div>
              <div>Please refresh the browser and login again.</div>
            </>
          ) : (
            <>
              <div className={classes.header}>Refresh Session</div>
              <div style={{ marginBottom: 20 }}>Your session has expired. Do you want to refresh the session?</div>
              <div className={classes.btnContainer}>
                <Button style={{ marginRight: 20 }} variant="contained" color="secondary" onClick={onRefreshToken}>
                  {`Yes `}
                  {isRefreshing && <CircularProgress style={{ marginLeft: 8 }} size={15} />}
                </Button>
                <Button variant="contained" color="inherit" onClick={onLogout}>
                  {`No`}
                </Button>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </Modal>
  );
};

export default TokenHandler;

import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import {
  eventDetailDiscardClickGAData,
  eventDetailModifyClickGAData,
  eventDetailDeleteDraftClickGAData,
  eventDetailCancelEventClickGAData,
} from 'src/app/common/ga/types/ga-event';
import { FormMode } from 'src/app/common/types';
import ButtonDialog from 'src/app/common/components/ButtonDialog';
import { useCommonFormStyles } from '../common-form.style';
import { useTopButton, HookProps } from './top-button.hook';

type TopButtonProps = HookProps & {
  formMode: FormMode;
  disableSave?: boolean;
  onSave?: () => void;
};

export const TopButton: FC<TopButtonProps> = ({ formMode, eventItem, disableSave, onSave }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes: commonFormClasses } = useCommonFormStyles();
  const {
    isDraft,
    isPublished,
    disableModify,
    allowCancel,
    handleDiscard,
    handleDelete,
    handleUnpublish,
    handleModify,
  } = useTopButton({
    eventItem,
  });

  return (
    <div className={commonFormClasses.topButtonContainer}>
      {/* Create Mode: Discard, Save & Continue */}
      {/* Edit Mode: Save & Continue */}
      {/* View Mode (Draft): Delete, Modify */}
      {/* View Mode (Published): Cancel & Unpublish, Modify */}
      {formMode === FormMode.CREATE && (
        <ButtonDialog
          titleTxt={Translation('app.button.discard')}
          dialogTxt={Translation('component.hint.cancel-warning')}
          btnTxt={Translation('app.button.discard')}
          onConfirm={() => {
            takeUIClickEvent(eventDetailDiscardClickGAData);
            handleDiscard();
          }}
        />
      )}
      {formMode === FormMode.VIEW ? (
        <>
          {/* Draft */}
          {isDraft && (
            <ButtonDialog
              titleTxt={Translation('event.form.delete_draft_event')}
              dialogTxt={Translation('event.form.delete_draft_event_reminder')}
              btnTxt={Translation('app.button.delete')}
              onConfirm={() => {
                takeUIClickEvent(eventDetailDeleteDraftClickGAData);
                handleDelete();
              }}
            />
          )}
          {/* Published */}
          {isPublished && allowCancel && (
            <ButtonDialog
              titleTxt={Translation('event.form.cancel_event')}
              dialogTxt={Translation('event.form.cancel_unpublish_reminder')}
              btnTxt={Translation('app.button.cancel_unpublish')}
              onConfirm={() => {
                takeUIClickEvent(eventDetailCancelEventClickGAData);
                handleUnpublish();
              }}
            />
          )}
          <Button
            disabled={disableModify}
            variant="contained"
            color="secondary"
            onClick={() => {
              takeUIClickEvent(eventDetailModifyClickGAData);
              handleModify();
            }}
          >
            {Translation('app.settings.security.modify')}
          </Button>
        </>
      ) : (
        <Button
          disabled={disableSave}
          variant="contained"
          color="secondary"
          onClick={() => {
            if (onSave) {
              onSave();
            }
          }}
        >
          {Translation('app.button.save_and_continue')}
        </Button>
      )}
    </div>
  );
};

import { FC } from 'react';
import { useIntl } from 'react-intl';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, RadioGroup, Radio } from '@mui/material';
import { regionLocale } from 'src/app/i18n';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { AttachmentDef, FormMode } from 'src/app/common/types';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import {
  eventDetailSelectTemplateClickGAData,
  eventDetailDownloadTemplateClickGAData,
  eventDetailToRsvpSaveClickGAData,
} from 'src/app/common/ga/types/ga-event';
import { ComponentProps } from 'src/app/common/components/pru-stepped-form-agent';
import { FileGallery } from 'src/app/common/components/file-gallery';
import { UploadArea } from 'src/app/common/components/upload-area-agent';
import Tinymce from 'src/app/common/components/Tinymce-storage-blob';
import { EventImageTypeEnum, EventFormCommonProps, DescriptionTypeEnum } from 'src/app/modules/event-v2/types';
import { DetailsFormState } from '../../../reducers';
import { useCommonFormStyles, MultiLangAccordion, TopButton } from '../../../common';
import { useDetailsStyles } from './details-form.style';
import { useDetailsForm } from './details-form.hook';

type EventDetailsFormProps = ComponentProps<EventFormCommonProps>;

export const EventDetailsForm: FC<EventDetailsFormProps> = ({ formCommonProps, ...rest }) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const { classes } = useDetailsStyles();
  const { classes: commonFormClasses } = useCommonFormStyles();
  const { formMode, disableEdit, eventItem } = formCommonProps;
  const { eventMaterialListItem, formState, errorState, formDispatch, onDismissErrorHandler, onSubmit } =
    useDetailsForm({
      formCommonProps,
      ...rest,
    });
  const viewMode = formMode === FormMode.CREATE || formMode === FormMode.VIEW;

  return (
    <>
      <TopButton
        formMode={formMode}
        eventItem={eventItem}
        onSave={() => {
          takeUIClickEvent(eventDetailToRsvpSaveClickGAData);
          onSubmit();
        }}
      />
      <div className={classes.container}>
        <MultiLangAccordion
          disabled={viewMode || disableEdit}
          error={(locale) =>
            errorState.mandatory[`eventImage-${locale}`] || errorState.mandatory[`description-${locale}`]
          }
          fillInOnChange={(locale) => {
            const noImageMaterial = (eventMaterialListItem?.images[locale] ?? []).length === 0;
            const noDescriptionMaterial = (eventMaterialListItem?.descriptions[locale] ?? []).length === 0;
            onDismissErrorHandler(
              [`eventImage-${locale}`, `descriptionImage-${locale}`, `description-${locale}`],
              true,
            );
            formDispatch({
              type: 'FILL_IN_DETAIL',
              payload: { fromLocale: regionLocale[0], toLocale: locale, noImageMaterial, noDescriptionMaterial },
            });
          }}
          renderChildren={(locale) => {
            const imageMaterials = eventMaterialListItem?.images[locale] ?? [];
            const descriptionMaterials = eventMaterialListItem?.descriptions[locale] ?? [];
            const eventImage = formState.eventImage[locale];
            const descriptionImage = formState.descriptionImage[locale];
            const attachments = formState.attachments[locale] as AttachmentDef[];
            return (
              <>
                <div className={commonFormClasses.smallGapContentContainer}>
                  <FormControl
                    fullWidth
                    disabled={viewMode || disableEdit}
                    error={
                      errorState.mandatory[`selectedEventImage-${locale}`] ||
                      errorState.mandatory[`eventImage-${locale}`]
                    }
                  >
                    <FormLabel
                      className={
                        imageMaterials.length > 0 ? commonFormClasses.formLabel : commonFormClasses.formLabelNoSpacing
                      }
                    >
                      {Translation('event.form.image')}
                      <span className={commonFormClasses.mandatory}>*</span>
                    </FormLabel>
                    {imageMaterials.length > 0 && (
                      <RadioGroup
                        className={commonFormClasses.radioGroup}
                        value={formState.eventImageType[locale]}
                        onChange={(e) => {
                          const dismissFields = [];
                          const newFormState: Partial<DetailsFormState> = {
                            eventImageType: {
                              ...formState.eventImageType,
                              [locale]: e.target.value as EventImageTypeEnum,
                            },
                          };
                          if (e.target.value === EventImageTypeEnum.TEMPLATE) {
                            dismissFields.push(`eventImage-${locale}`);
                            newFormState.eventImage = { ...formState.eventImage, [locale]: undefined };
                          } else {
                            dismissFields.push(`selectedEventImage-${locale}`);
                            newFormState.selectedEventImage = { ...formState.selectedEventImage, [locale]: '' };
                          }
                          onDismissErrorHandler(dismissFields, true);
                          formDispatch({
                            type: 'SET_FORM_STATE',
                            payload: {
                              value: newFormState,
                            },
                          });
                        }}
                      >
                        <FormControlLabel
                          key={EventImageTypeEnum.TEMPLATE}
                          className={commonFormClasses.formControlLabel}
                          control={<Radio />}
                          label={Translation(`event.form.template.option.${EventImageTypeEnum.TEMPLATE.toLowerCase()}`)}
                          value={EventImageTypeEnum.TEMPLATE}
                        />
                        <FormControlLabel
                          key={EventImageTypeEnum.UPLOAD}
                          className={commonFormClasses.formControlLabel}
                          control={<Radio />}
                          label={Translation(`event.form.template.option.${EventImageTypeEnum.UPLOAD.toLowerCase()}`)}
                          value={EventImageTypeEnum.UPLOAD}
                        />
                      </RadioGroup>
                    )}
                  </FormControl>
                  {formState.eventImageType[locale] === EventImageTypeEnum.TEMPLATE && (
                    <FileGallery
                      disableLightbox
                      disabled={viewMode || disableEdit}
                      error={errorState.mandatory[`selectedEventImage-${locale}`]}
                      files={imageMaterials.map((material) => ({ ...material.blob, _id: material._id }))}
                      selected={formState.selectedEventImage[locale]}
                      onSelect={(e, file) => {
                        if (e.target.checked) {
                          takeUIClickEvent(eventDetailSelectTemplateClickGAData);
                          onDismissErrorHandler(`selectedEventImage-${locale}`, true);
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'selectedEventImage',
                              value: { ...formState.selectedEventImage, [locale]: file._id },
                            },
                          });
                        } else {
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'selectedEventImage',
                              value: { ...formState.selectedEventImage, [locale]: '' },
                            },
                          });
                        }
                      }}
                    />
                  )}
                  {formState.eventImageType[locale] === EventImageTypeEnum.UPLOAD && (
                    <>
                      <div className={classes.remark}>{Translation('event.form.image.remark.agent')}</div>
                      <UploadArea
                        id={`eventImage-${locale}`}
                        enableImageRatioReminder
                        module="event"
                        accessLevel="public"
                        disabled={viewMode || disableEdit}
                        error={errorState.mandatory[`eventImage-${locale}`]}
                        accept="image/jpeg,image/png"
                        sources={eventImage ? [eventImage] : []}
                        uploadText={Translation('common.upload_image')}
                        maxFileSize={300}
                        maxFileSizeUnit="KB"
                        onChange={(attachment) => {
                          onDismissErrorHandler(`eventImage-${locale}`, true);
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: { field: 'eventImage', value: { ...formState.eventImage, [locale]: attachment } },
                          });
                        }}
                        onRemove={() =>
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: { field: 'eventImage', value: { ...formState.eventImage, [locale]: undefined } },
                          })
                        }
                      />
                    </>
                  )}
                </div>
                <div className={commonFormClasses.smallGapContentContainer}>
                  <FormControl
                    fullWidth
                    disabled={viewMode || disableEdit}
                    error={
                      errorState.mandatory[`descriptionImage-${locale}`] ||
                      errorState.mandatory[`description-${locale}`]
                    }
                  >
                    <FormLabel className={commonFormClasses.formLabel}>
                      {Translation('event.form.event_details')}
                      <span className={commonFormClasses.mandatory}>*</span>
                    </FormLabel>
                    <RadioGroup
                      className={commonFormClasses.radioGroup}
                      value={formState.descriptionType[locale]}
                      onChange={(e) => {
                        const dismissFields = [];
                        const newFormState: Partial<DetailsFormState> = {
                          descriptionType: {
                            ...formState.descriptionType,
                            [locale]: e.target.value as DescriptionTypeEnum,
                          },
                        };
                        if (e.target.value !== DescriptionTypeEnum.NO_TEMPLATE) {
                          dismissFields.push(`description-${locale}`);
                          newFormState.description = { ...formState.description, [locale]: '' };
                        } else {
                          dismissFields.push(`descriptionImage-${locale}`);
                          newFormState.descriptionImage = { ...formState.descriptionImage, [locale]: undefined };
                        }
                        onDismissErrorHandler(dismissFields, true);
                        formDispatch({
                          type: 'SET_FORM_STATE',
                          payload: {
                            value: newFormState,
                          },
                        });
                      }}
                    >
                      {descriptionMaterials.length > 0 && (
                        <FormControlLabel
                          key={DescriptionTypeEnum.TEMPLATE}
                          className={commonFormClasses.formControlLabel}
                          control={<Radio />}
                          label={Translation(
                            `event.form.template.option.${DescriptionTypeEnum.TEMPLATE.toLowerCase()}`,
                          )}
                          value={DescriptionTypeEnum.TEMPLATE}
                        />
                      )}
                      <FormControlLabel
                        key={DescriptionTypeEnum.UPLOAD}
                        className={commonFormClasses.formControlLabel}
                        control={<Radio />}
                        label={Translation(`event.form.template.option.${DescriptionTypeEnum.UPLOAD.toLowerCase()}`)}
                        value={DescriptionTypeEnum.UPLOAD}
                      />
                      <FormControlLabel
                        key={DescriptionTypeEnum.NO_TEMPLATE}
                        className={commonFormClasses.formControlLabel}
                        control={<Radio />}
                        label={Translation(
                          `event.form.template.option.${DescriptionTypeEnum.NO_TEMPLATE.toLowerCase()}`,
                        )}
                        value={DescriptionTypeEnum.NO_TEMPLATE}
                      />
                    </RadioGroup>
                  </FormControl>
                  {formState.descriptionType[locale] === DescriptionTypeEnum.TEMPLATE && (
                    <>
                      <div className={classes.remark}>{Translation('event.form.event_details.template.remark')}</div>
                      <FileGallery
                        onDownloadClickGAData={eventDetailDownloadTemplateClickGAData}
                        disablePreviewImage
                        files={descriptionMaterials.map((material) => material.blob)}
                      />
                    </>
                  )}
                  {formState.descriptionType[locale] !== DescriptionTypeEnum.NO_TEMPLATE && (
                    <>
                      {formState.descriptionType[locale] === DescriptionTypeEnum.UPLOAD && (
                        <div className={classes.remark}>{Translation('event.form.event_details.upload.remark')}</div>
                      )}
                      <UploadArea
                        id={`descriptionImage-${locale}`}
                        module="event"
                        accessLevel="public"
                        disabled={viewMode || disableEdit}
                        error={errorState.mandatory[`descriptionImage-${locale}`]}
                        accept="image/jpeg,image/png,application/pdf"
                        sources={descriptionImage ? [descriptionImage] : []}
                        uploadText={Translation('common.upload_file')}
                        maxFileSize={20}
                        maxFileSizeUnit="MB"
                        onChange={(attachment) => {
                          onDismissErrorHandler(`descriptionImage-${locale}`, true);
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'descriptionImage',
                              value: { ...formState.descriptionImage, [locale]: attachment },
                            },
                          });
                        }}
                        onRemove={() =>
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'descriptionImage',
                              value: { ...formState.descriptionImage, [locale]: undefined },
                            },
                          })
                        }
                      />
                    </>
                  )}
                </div>
                {formState.descriptionType[locale] === DescriptionTypeEnum.NO_TEMPLATE && (
                  <>
                    <FormControl
                      fullWidth
                      disabled={viewMode || disableEdit}
                      error={errorState.mandatory[`description-${locale}`]}
                    >
                      <FormLabel className={`${commonFormClasses.formLabel} ${classes.textMargin}`}>
                        {Translation('event.form.about_this_event')}
                        <span className={commonFormClasses.mandatory}>*</span>
                      </FormLabel>
                      <div className={`${classes.remark} ${classes.textMargin}`}>
                        {Translation('event.form.about_this_event.remark')}
                      </div>
                      <Tinymce
                        disabled={viewMode || disableEdit}
                        module="event"
                        accessLevel="anonymous"
                        value={formState.description[locale] || ''}
                        onChange={(value) => {
                          formDispatch({
                            type: 'MODIFY_DESCRIPTION',
                            payload: { value: { [locale]: value } },
                          });
                        }}
                      />
                      {errorState.mandatory[`description-${locale}`] && (
                        <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                      )}
                    </FormControl>
                    <div className={commonFormClasses.smallGapContentContainer}>
                      <FormControl disabled={viewMode || disableEdit}>
                        <FormLabel className={`${commonFormClasses.formLabel} ${classes.textMargin}`}>
                          {Translation('event.list.detail.additional_attachment')}
                        </FormLabel>
                        <div className={classes.remark}>{Translation('event.form.attachments.remark')}</div>
                      </FormControl>
                      <UploadArea
                        id={`attachments-${locale}`}
                        module="event"
                        accessLevel="public"
                        disabled={viewMode || disableEdit}
                        multiple
                        accept="image/jpeg,image/png,application/pdf,video/mp4"
                        sources={attachments || []}
                        uploadText={Translation('common.upload_files')}
                        maxFileNum={5}
                        maxFileSize={20}
                        maxFileSizeUnit="MB"
                        onChange={(attachment) =>
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'attachments',
                              value: { ...formState.attachments, [locale]: [...attachments, attachment] },
                            },
                          })
                        }
                        onRemove={(sourceIndex) =>
                          formDispatch({
                            type: 'REMOVE_ATTACHMENT',
                            payload: { locale, index: sourceIndex },
                          })
                        }
                      />
                    </div>
                  </>
                )}
              </>
            );
          }}
        />
      </div>
    </>
  );
};

import { FC, Dispatch } from 'react';
import { map } from 'lodash';
import { useIntl } from 'react-intl';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  RadioGroup,
  Radio,
  TextField,
  Tooltip,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import { regionLocale } from 'src/app/i18n';
import {
  MANDATORY_FIELD_ERROR_TEXT,
  TIME_ERROR_TEXT,
  NUMBER_NA_ONLY_ERROR_TEXT,
  trueFalseOptions,
} from 'src/app/common/constants';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import { eventDetailSessionConfirmClickGAData } from 'src/app/common/ga/types/ga-event';
import PruIcon from 'src/app/common/components/PruIcon';
import { PruTimePicker } from 'src/app/common/components/PruDatePicker';
import { OnlineMeetingLinkDisplayEnum, SessionEnrollmentEnum, EventSessionItem } from 'src/app/modules/event-v2/types';
import { BasicsFormAction, SessionFormState } from 'src/app/modules/event-v2/pages/event-list/form/reducers';
import { useCommonFormStyles, MultiLangSection } from 'src/app/modules/event-v2/pages/event-list/form/common';
import { useStyles } from './session-form.style';
import { useSessionForm } from './session-form.hook';
import { SubSessionForm } from './sub-session-form.component';

type SessionFormProps = {
  viewMode: boolean;
  isPublished: boolean;
  disableEdit: boolean;
  isVirtual: boolean;
  sessionNum: number;
  sessionItem?: EventSessionItem;
  updateBasicsForm: Dispatch<BasicsFormAction>;
  onClose: () => void;
};

export const SessionForm: FC<SessionFormProps> = ({
  viewMode,
  isPublished,
  disableEdit,
  isVirtual,
  sessionNum,
  sessionItem,
  updateBasicsForm,
  onClose,
}) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const { classes } = useStyles();
  const { classes: commonFormClasses } = useCommonFormStyles();
  const { formState, errorState, formDispatch, onDismissErrorHandler, onSubmit } = useSessionForm({
    isVirtual,
    sessionNum,
    sessionItem,
    updateBasicsForm,
    onClose,
  });

  return (
    <div className={classes.container}>
      <div className={commonFormClasses.titleContainer}>
        {Translation('event.form.session')} {sessionNum}
      </div>
      <div className={commonFormClasses.contentContainer}>
        <MultiLangSection
          disabled={viewMode || disableEdit}
          fillInOnChange={(locale) => {
            onDismissErrorHandler([`name-${locale}`], true);
            formDispatch({
              type: 'FILL_IN_DETAIL',
              payload: { fromLocale: regionLocale[0], toLocale: locale },
            });
          }}
          renderChildren={(locale) => (
            <>
              <div>
                <TextField
                  disabled={viewMode || disableEdit}
                  fullWidth
                  size="medium"
                  variant="outlined"
                  label={
                    <>
                      {Translation('event.form.session.name', {
                        num: sessionNum.toString(),
                      })}
                      <span className={commonFormClasses.mandatory}>*</span>
                    </>
                  }
                  placeholder={Translation('event.form.session.name.placeholder')}
                  error={errorState.mandatory[`name-${locale}`]}
                  helperText={errorState.mandatory[`name-${locale}`] && MANDATORY_FIELD_ERROR_TEXT}
                  value={formState.name[locale] || ''}
                  onChange={(e) => {
                    onDismissErrorHandler(`name-${locale}`, e.target.value);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'name', value: { ...formState.name, [locale]: e.target.value } },
                    });
                  }}
                />
              </div>
              <div>
                <TextField
                  disabled={viewMode || disableEdit}
                  fullWidth
                  size="medium"
                  variant="outlined"
                  label={Translation('event.list.detail.venue')}
                  placeholder={Translation('event.form.session.venue.placeholder')}
                  value={formState.venue[locale] || ''}
                  onChange={(e) => {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'venue', value: { ...formState.venue, [locale]: e.target.value } },
                    });
                  }}
                />
              </div>
            </>
          )}
        />
        {isVirtual && (
          <div>
            <FormLabel
              disabled={viewMode || disableEdit}
              className={commonFormClasses.formLabel}
              error={errorState.immediate.onlineMeetingLinkFormat}
            >
              {Translation('event.form.online_meeting_link')}
            </FormLabel>
            <div className={commonFormClasses.smallGapContentContainer}>
              <div>
                <TextField
                  disabled={viewMode || disableEdit}
                  fullWidth
                  size="medium"
                  variant="outlined"
                  label={Translation('event.form.online_meeting_link')}
                  placeholder={Translation('event.form.online_meeting_link.placeholder')}
                  error={errorState.immediate.onlineMeetingLinkFormat}
                  helperText={errorState.immediate.onlineMeetingLinkFormat && Translation('component.form-link-text')}
                  value={formState.onlineMeetingLink || ''}
                  onChange={(e) => {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'onlineMeetingLink', value: e.target.value },
                    });
                  }}
                />
                <div className={commonFormClasses.remark}>{Translation('event.form.online_meeting_link.remark')}</div>
              </div>
              <FormControl fullWidth disabled={viewMode || disableEdit}>
                <FormLabel className={commonFormClasses.formLabelMargin}>
                  {Translation('event.form.online_meeting_link_display')}
                </FormLabel>
                <FormGroup className={commonFormClasses.radioGroup}>
                  {map(OnlineMeetingLinkDisplayEnum, (option) => (
                    <FormControlLabel
                      key={`onlineMeetingLinkDisplay-${option}`}
                      className={commonFormClasses.formControlLabel}
                      control={
                        <Checkbox
                          checked={!!formState.onlineMeetingLinkDisplay?.includes(option)}
                          onChange={(e) =>
                            formDispatch({
                              type: 'MODIFY_FIELD',
                              payload: {
                                field: 'onlineMeetingLinkDisplay',
                                value: e.target.checked
                                  ? [...(formState.onlineMeetingLinkDisplay || []), option]
                                  : formState.onlineMeetingLinkDisplay?.filter((item) => item !== option),
                              },
                            })
                          }
                        />
                      }
                      label={
                        <span className={commonFormClasses.formLabelContainer}>
                          {Translation(`event.form.online_meeting_link_display.${option.toLowerCase()}`)}
                          <Tooltip
                            arrow
                            placement="bottom"
                            title={Translation(
                              `event.form.online_meeting_link_display.${option.toLowerCase()}.tooltip`,
                            )}
                          >
                            <IconButton size={'small'} className={commonFormClasses.infoIcon}>
                              <Info />
                            </IconButton>
                          </Tooltip>
                        </span>
                      }
                      labelPlacement="end"
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        )}
        <div>
          <FormLabel
            disabled={viewMode || disableEdit}
            className={commonFormClasses.formLabel}
            error={
              errorState.mandatory.startTime ||
              errorState.mandatory.endTime ||
              errorState.immediate.endTimeBeforeStartTime
            }
          >
            {Translation('event.form.session.time')}
          </FormLabel>
          <div className={commonFormClasses.doublePickerContainer}>
            <FormControl
              disabled={viewMode || disableEdit}
              className={commonFormClasses.dateTimePickerField}
              error={errorState.mandatory.startTime || errorState.immediate.endTimeBeforeStartTime}
            >
              <PruTimePicker
                disabled={viewMode || disableEdit}
                ampm={false}
                label={
                  <>
                    {Translation('event.form.session.start_time')}
                    <span className={commonFormClasses.mandatory}>*</span>
                  </>
                }
                slotProps={{
                  textField: {
                    size: 'medium',
                    variant: 'outlined',
                    error: errorState.mandatory.startTime || errorState.immediate.endTimeBeforeStartTime,
                    helperText: errorState.mandatory.startTime && MANDATORY_FIELD_ERROR_TEXT,
                  },
                }}
                value={formState.startTime}
                onChange={(date) => {
                  onDismissErrorHandler('startTime', date);
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'startTime', value: date } });
                }}
              />
            </FormControl>
            <div>{Translation('common.to')}</div>
            <FormControl
              disabled={viewMode || disableEdit}
              className={commonFormClasses.dateTimePickerField}
              error={errorState.mandatory.endTime || errorState.immediate.endTimeBeforeStartTime}
            >
              <PruTimePicker
                disabled={viewMode || disableEdit}
                ampm={false}
                label={
                  <>
                    {Translation('event.form.session.end_time')}
                    <span className={commonFormClasses.mandatory}>*</span>
                  </>
                }
                slotProps={{
                  textField: {
                    size: 'medium',
                    variant: 'outlined',
                    error: errorState.mandatory.endTime || errorState.immediate.endTimeBeforeStartTime,
                    helperText: errorState.immediate.endTimeBeforeStartTime
                      ? TIME_ERROR_TEXT
                      : errorState.mandatory.endTime && MANDATORY_FIELD_ERROR_TEXT,
                  },
                }}
                value={formState.endTime}
                onChange={(date) => {
                  onDismissErrorHandler('endTime', date);
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'endTime', value: date } });
                }}
              />
            </FormControl>
          </div>
        </div>
        <div className={commonFormClasses.smallGapContentContainer}>
          <FormControl
            fullWidth
            disabled={viewMode || isPublished || disableEdit}
            error={errorState.mandatory.multiSubSession}
          >
            <FormLabel className={commonFormClasses.formLabel}>
              {Translation('event.form.session.multiple_sub_sessions')}
              <span className={commonFormClasses.mandatory}>*</span>
              <Tooltip arrow placement="top" title={Translation('event.form.multiple_sub_sessions.tooltip')}>
                <IconButton size={'small'} className={commonFormClasses.infoIcon}>
                  <Info />
                </IconButton>
              </Tooltip>
            </FormLabel>
            <RadioGroup
              className={commonFormClasses.radioGroup}
              value={formState.multiSubSession !== undefined ? formState.multiSubSession.toString() : ''}
              onChange={(e) => {
                const dismissFields = ['multiSubSession'];
                const newFormState: Partial<SessionFormState> = {
                  multiSubSession: e.target.value === 'true' ? true : false,
                };
                if (e.target.value === 'true') {
                  dismissFields.push('quota');
                  newFormState.quota = undefined;
                } else {
                  dismissFields.push('subSessionEnrollment');
                  newFormState.subSessionEnrollment = undefined;
                  newFormState.subSessions = [];
                }
                onDismissErrorHandler(dismissFields, true);
                formDispatch({
                  type: 'SET_FORM_STATE',
                  payload: {
                    value: newFormState,
                  },
                });
              }}
            >
              {trueFalseOptions.map((option) => (
                <FormControlLabel
                  key={option}
                  className={commonFormClasses.formControlLabel}
                  control={<Radio />}
                  label={Translation(`event.form.multiple_sessions.${option}`)}
                  value={option}
                />
              ))}
            </RadioGroup>
            {errorState.mandatory.multiSubSession && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
          </FormControl>
          {formState.multiSubSession === true && (
            <>
              {formState.subSessions.map((subSessionItem, index) => (
                <SubSessionForm
                  key={`sub-session-${index}`}
                  viewMode={viewMode}
                  isPublished={isPublished}
                  disableEdit={disableEdit}
                  isVirtual={isVirtual}
                  sessionNum={sessionNum}
                  subSessionNum={index + 1}
                  subSessionItem={subSessionItem}
                  errorState={errorState}
                  onDismissErrorHandler={onDismissErrorHandler}
                  updateSessionForm={(newSubSessionItem) =>
                    formDispatch({ type: 'MODIFY_SUB_SESSION', payload: { index, value: newSubSessionItem } })
                  }
                  removeSubSession={() => formDispatch({ type: 'REMOVE_SUB_SESSION', payload: { index } })}
                />
              ))}
              <Button
                disabled={viewMode || isPublished || disableEdit}
                className={classes.addSubSessionButton}
                variant="outlined"
                onClick={() => formDispatch({ type: 'ADD_SUB_SESSION', payload: { regionLocale } })}
              >
                <PruIcon icon="instruction_add" style={{ fontSize: 20 }} />
                {Translation('event.form.session.add_sub')}
              </Button>
            </>
          )}
        </div>
        {formState.multiSubSession === false && (
          <FormControl
            fullWidth
            disabled={viewMode || isPublished || disableEdit}
            error={errorState.mandatory.quota || errorState.immediate.quotaFormat}
          >
            <FormLabel className={commonFormClasses.formLabel}>
              {Translation('event.form.session.quota')}
              <span className={commonFormClasses.mandatory}>*</span>
            </FormLabel>
            <TextField
              disabled={viewMode || isPublished || disableEdit}
              size="medium"
              variant="outlined"
              error={errorState.mandatory.quota || errorState.immediate.quotaFormat}
              helperText={
                errorState.immediate.quotaFormat
                  ? NUMBER_NA_ONLY_ERROR_TEXT
                  : errorState.mandatory.quota && MANDATORY_FIELD_ERROR_TEXT
              }
              value={formState.quota || ''}
              onChange={(e) => {
                onDismissErrorHandler('quota', e.target.value);
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { field: 'quota', value: e.target.value },
                });
              }}
            />
            <div className={`${commonFormClasses.remark} ${commonFormClasses.remarkMargin}`}>
              {Translation('event.form.session.quota.placeholder')}
            </div>
          </FormControl>
        )}
        {formState.multiSubSession === true && formState.subSessions.length > 0 && (
          <div>
            <FormControl
              fullWidth
              disabled={viewMode || isPublished || disableEdit}
              error={errorState.mandatory.subSessionEnrollment}
            >
              <FormLabel className={commonFormClasses.formLabel}>
                {Translation('event.form.session.session_enrollment')}
                <span className={commonFormClasses.mandatory}>*</span>
              </FormLabel>
              <RadioGroup
                className={commonFormClasses.radioGroup}
                value={formState.subSessionEnrollment || ''}
                onChange={(e) => {
                  onDismissErrorHandler('subSessionEnrollment', e.target.value);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'subSessionEnrollment', value: e.target.value },
                  });
                }}
              >
                {map(SessionEnrollmentEnum, (option) => (
                  <FormControlLabel
                    key={`session-enroll-${option}`}
                    className={commonFormClasses.formControlLabel}
                    control={<Radio />}
                    label={Translation(`event.form.session.sub_session_enrollment.${option.toLowerCase()}`)}
                    value={option}
                  />
                ))}
              </RadioGroup>
              {errorState.mandatory.subSessionEnrollment && (
                <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
              )}
            </FormControl>
          </div>
        )}
        <div className={classes.footerContainer}>
          <Button variant="outlined" color="inherit" onClick={() => onClose()}>
            {Translation('app.button.cancel')}
          </Button>
          <Button
            disabled={viewMode || disableEdit}
            variant="contained"
            color="secondary"
            onClick={() => {
              takeUIClickEvent(eventDetailSessionConfirmClickGAData);
              onSubmit();
            }}
          >
            {Translation('app.button.confirm')}
          </Button>
        </div>
      </div>
    </div>
  );
};

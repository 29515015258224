import React, { FC, memo, useMemo } from 'react';
import { Box, Button, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import {
  eventDetailExportParticipantClickGAData,
  eventDetailExportResultClickGAData,
} from 'src/app/common/ga/types/ga-event';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { FilterIcon } from 'src/app/common/components/svg-icon/svg-icon.component';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useParticipantTableBar } from './participant-table-bar.hook';
import { ListFilterComponent } from 'src/app/common/components/list-filter';
import { getFilterConfig } from '../../util/participant.util';
import { EventItem, RegistrationTypeEnum } from 'src/app/modules/event-v2/types';
import { defaultFilterState } from '../../participant-list/participant-list.hook';
import { useParams } from 'react-router-dom';

type ComponentProps = ParamsProps & {
  curTab: string;
  filterState: Record<string, any>;
  isEmpty: boolean;
  walkInAllowed?: boolean;
  onTabChange?: (value: string) => void;
  onFilterChanged: (value: any, cb: () => void) => void;
  eventItem?: EventItem;
};

export const ParticipantTableBarComponent: React.FC<ComponentProps> = memo(
  ({ curTab, isEmpty, filterState, onTabChange, onFilterChanged, walkInAllowed, eventItem }: ComponentProps) => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();

    const { tabs, showFilter, isExporting, isExportingFormResult, setShowFilter, onExport, onExportFormResult } =
      useParticipantTableBar({
        eventId: id,
        filterState,
        eventItem,
        walkInAllowed,
      });

    return (
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mb: '20px' }}>
        <Tabs
          value={curTab}
          onChange={(e, value) => {
            onTabChange?.(value);
          }}
          TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
          sx={{
            '.Mui-selected': {
              color: '#333333 !important',
            },
          }}
        >
          {tabs?.map((tab, index) => {
            return (
              <Tab
                key={index}
                value={tab.value}
                label={tab.label}
                sx={{ fontSize: 18, fontWeight: 600, color: '#999999' }}
                onClick={() => {
                  if (tab.gaData) {
                    takeUIClickEvent(tab.gaData);
                  }
                }}
              />
            );
          })}
        </Tabs>

        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Button onClick={() => setShowFilter(true)} variant="text" sx={{ mr: '20px' }}>
            <FilterIcon sx={{ mr: 1 }} />
            <Typography>{t('pruleads.prospect.list.filter')}</Typography>
          </Button>

          <Button
            disabled={isExportingFormResult || isEmpty}
            onClick={() => {
              takeUIClickEvent(eventDetailExportResultClickGAData);
              onExportFormResult();
            }}
            variant="outlined"
            color="info"
            sx={{ mr: 2 }}
          >
            <Typography>{t('event.form.registration_form_result')}</Typography>
          </Button>

          <Button
            disabled={isExporting || isEmpty}
            onClick={() => {
              takeUIClickEvent(eventDetailExportParticipantClickGAData);
              onExport();
            }}
            variant="contained"
            color="secondary"
          >
            <Typography>{t('export.list.text')}</Typography>
          </Button>
        </Box>

        {showFilter ? (
          <ListFilterComponent
            open={showFilter}
            filterValues={filterState}
            defaultValues={{ ...defaultFilterState, registrationType: curTab }}
            filterConfigs={getFilterConfig({ t, isWalkInList: curTab === RegistrationTypeEnum.WALKIN })}
            onClose={() => setShowFilter(false)}
            onConfirm={(value) => {
              onFilterChanged({ ...value }, () => setShowFilter(false));
            }}
          />
        ) : null}
      </Stack>
    );
  },
);

import React, { FC, memo, useMemo, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

import { takeUIScreenViewEvent } from 'src/app/common/ga/ga';
import { eventDetailParticipantDetailGAData } from 'src/app/common/ga/types/ga-event';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { Card } from 'src/app/common/components/card/card.component';
import { ParticipantRoleEnum } from 'src/app/modules/event-v2/types';
import { map } from 'lodash';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { useHistory, useParams } from 'react-router-dom';
import { useLang } from 'src/app/i18n';
import moment from 'moment';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useRequest } from 'ahooks';
import { fetchParticipantDetailItem } from 'src/app/modules/event-v2/network/participant-crud';
import { useDispatch } from 'react-redux';
import { useCommonFormStyles } from '../../../common';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
};

const attendanceStatusOptions = [
  {
    label: 'attended',
    value: true,
  },
  {
    label: 'not_attended',
    value: false,
  },
];

export const ParticipantDetailComponent: React.FC<ComponentProps> = memo(() => {
  // i18n
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const locale = useLang();
  const { participantId, id: eventId } = useParams<{ id: string; participantId: string }>();
  const { classes: commonFormClasses } = useCommonFormStyles();

  const goBack = () => {
    history.goBack();
  };

  const { data, error, loading } = useRequest(() => fetchParticipantDetailItem(eventId, participantId, dispatch));

  const multiSession = useMemo(() => {
    return data?.sessions && data?.sessions?.length > 0;
  }, [data]);

  const roleFiledConfig = useMemo(() => {
    const { referer, firstName, lastName, phoneNumber, email } = data ?? {};
    return [
      {
        label: t('event.form.referred_agent_code'),
        value: referer,
      },
      {
        label: '',
        value: '',
      },
      {
        label: t('event.form.first_name'),
        value: firstName,
        required: true,
      },
      {
        label: t('event.form.last_name'),
        value: lastName,
      },
      {
        label: t('event.form.contact.mobile'),
        value: phoneNumber,
      },
      {
        label: t('event.form.email_address'),
        value: email,
      },
    ];
  }, [data]);

  const attendanceFiledConfig = useMemo(() => {
    const { checkInAt, checkOutAt, duration } = data ?? {};
    return [
      {
        label: t('event.form.check_in_time'),
        value: checkInAt ? getDefaultDisplayDate(checkInAt, 'datetime') : checkInAt,
      },
      {
        label: t('event.form.check_out_time'),
        value: checkOutAt ? getDefaultDisplayDate(checkOutAt, 'datetime') : checkOutAt,
      },
      {
        label: t('event.form.duration'),
        value: duration,
      },
    ];
  }, [data, t]);

  useEffect(() => {
    takeUIScreenViewEvent(eventDetailParticipantDetailGAData);
  }, []);

  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle
        title={t('event.form.participant_details')}
        rightNode={
          <Button variant="outlined" color="secondary" onClick={goBack}>
            {t('app.button.back')}
          </Button>
        }
      />
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 25 }}>
          <CircularProgress color="secondary" size={44} />
        </Box>
      ) : (
        <Card sx={{ mt: '20px', p: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {multiSession && (
                <Typography sx={{ mb: 3, fontSize: 18, fontWeight: 600 }}>
                  {t('event.form.participant_information')}
                </Typography>
              )}
              <FormControl fullWidth disabled>
                <FormLabel sx={{ mb: 2, fontSize: 14, fontWeight: 600, color: '#333 !important' }} color="secondary">
                  {t('event.form.role')}
                  <span style={{ color: 'red' }}>*</span>
                </FormLabel>
                <RadioGroup value={data?.role || ''} row sx={{ gap: 3 }}>
                  {map(ParticipantRoleEnum, (option) => (
                    <FormControlLabel
                      key={`role-${option}`}
                      control={<Radio size="medium" />}
                      label={t(`event.form.role.${option.toLowerCase()}`)}
                      value={option}
                      sx={{ flex: 1, border: '1px solid rgba(0, 0, 0, 0.38)', borderRadius: 1, mx: 0, py: '6px' }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            {roleFiledConfig?.map(({ value, label, required }) => {
              return <ParticipantGridItem value={value || ''} label={label} key={label} required={required} />;
            })}

            {!multiSession && (
              <>
                <Grid item xs={12}>
                  <FormControl fullWidth disabled>
                    <FormLabel sx={{ mb: 2, fontSize: 14, fontWeight: 600, color: '#333 !important' }}>
                      {t('event.form.attendance_status')}
                    </FormLabel>
                    <RadioGroup value={String(data?.isAttended)} row sx={{ gap: 3 }}>
                      {attendanceStatusOptions?.map(({ label, value }) => (
                        <FormControlLabel
                          key={`attendance-status-${value}`}
                          control={<Radio size="medium" />}
                          label={t(`event.form.attendance_status.${label}`)}
                          value={String(value)}
                          sx={{ flex: 1, border: '1px solid rgba(0, 0, 0, 0.38)', borderRadius: 1, mx: 0, py: '6px' }}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {attendanceFiledConfig?.map(({ value, label }) => {
                  return <ParticipantGridItem value={value || ''} label={label} key={label} />;
                })}
              </>
            )}
          </Grid>

          {multiSession && (
            <>
              <Typography sx={{ my: 3, fontSize: 18, fontWeight: 600 }}>{t('event.form.session_rsvp')}</Typography>
              <PruTable
                disableBulkSelect
                disablePagination
                disableRefresh
                renderChildren
                hideListTitleRow
                containerClassName={commonFormClasses.sessionTableContainer}
                childRowClassName={commonFormClasses.subSessionRow}
                headerBtnDef={[]}
                operationDef={[]}
                columnDef={[
                  {
                    keyIndex: 'session',
                    displayName: t('event.list.detail.sessions'),
                    renderData: (_, index, parent) =>
                      parent
                        ? `${t('event.form.sub_session')} ${index + 1}`
                        : `${t('event.form.session')} ${index + 1}`,
                  },
                  {
                    keyIndex: 'name',
                    displayName: t('event.list.detail.session_name'),
                    renderData: (row) => row.name[locale] || '-',
                  },
                  {
                    keyIndex: 'session_time',
                    displayName: t('event.form.session.time'),
                    renderData: (row) =>
                      `${moment(row.startTime).format('HH:mm')} - ${moment(row.endTime).format('HH:mm')}` || '-',
                  },
                  {
                    keyIndex: 'isRegistered',
                    displayName: t('event.form.registration'),
                    renderData: (row, _, parent) =>
                      !row.subSessions || row.subSessions.length === 0 || parent
                        ? row.isRegistered
                          ? 'Yes'
                          : 'No'
                        : '-',
                  },
                  {
                    keyIndex: 'attendanceStatus',
                    displayName: t('event.form.attendance_status.attended'),
                    renderData: (row, _, parent) =>
                      !row.subSessions || row.subSessions.length === 0 || parent
                        ? row.isAttended
                          ? 'Yes'
                          : 'No'
                        : '-',
                  },
                  {
                    keyIndex: 'checkInAt',
                    displayName: t('event.form.check_in_time'),
                    renderData: (row, _, parent) =>
                      !row.subSessions || row.subSessions.length === 0 || parent
                        ? getDefaultDisplayDate(row.checkInAt, 'datetime')
                        : '-',
                  },
                  {
                    keyIndex: 'checkOutAt',
                    displayName: t('event.form.check_out_time'),
                    renderData: (row, _, parent) =>
                      !row.subSessions || row.subSessions.length === 0 || parent
                        ? getDefaultDisplayDate(row.checkOutAt, 'datetime')
                        : '-',
                  },
                  {
                    keyIndex: 'duration',
                    displayName: t('event.form.duration'),
                    renderData: (row, _, parent) =>
                      !row.subSessions || row.subSessions.length === 0 || parent ? row.duration || '-' : '-',
                  },
                  {
                    isId: true,
                    hidden: true,
                    keyIndex: 'key',
                    childKeyIndex: 'subSessions',
                    displayName: '',
                    renderData: () => '',
                  },
                ]}
                isLoading={false}
                dataSource={data?.sessions || []}
              />
            </>
          )}
        </Card>
      )}
    </Page>
  );
});

const ParticipantGridItem = ({ value, label, required }: { value: string; label: string; required?: boolean }) => {
  return (
    <Grid item xs={6}>
      {label && (
        <TextField
          size="medium"
          fullWidth
          disabled
          required={required}
          variant="outlined"
          label={label}
          value={value}
        />
      )}
    </Grid>
  );
};
